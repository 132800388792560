import type { StoreApi } from 'zustand/vanilla';
import { produce } from 'immer';
import type { GlobalStateStore, DeviceDetailsType } from '../types';

export type DeviceVariantActionProps = {
  operation: 'SWITCH_DEVICE';
  payload: {
    deviceDetails: DeviceDetailsType;
  };
};

const getDeviceVariantActions =
  (set: StoreApi<GlobalStateStore>['setState']) =>
  ({ operation, payload }: DeviceVariantActionProps) => {
    switch (operation) {
      case 'SWITCH_DEVICE': {
        set((state) =>
          produce(state, (draft) => {
            draft.deviceDetails = payload.deviceDetails;
          }),
        );
      }
    }
  };

export default getDeviceVariantActions;
