import { type StateCreator, create } from 'zustand';
import { ROOT_ID } from '../../const';
import type {
  AdditionalArgs,
  InterfaceStoreState,
  InterfaceStoreStateInitialValue,
  InterfaceStoreType,
} from './types';
import getCreateBlockAction from './actions/createBlock';
import getInsertBlockAction from './actions/insertBlock';
import getRemoveBlockAction from './actions/removeBlock';
import getDeleteBlockAction from './actions/deleteBlock';
import getMoveBlockAction from './actions/moveBlock';
import getReorderBlockAction from './actions/reorderBlock';
import getSetActiveBlockIdAction from './actions/setActiveBlockIdAction';
import getSetHoverBlockIdAction from './actions/setHoverBlockIdAction';
import getIncrementBlockCounter from './actions/incrementBlockCounter';
import getRemoveSlotInBlock from './actions/removeSlotInBlock';
import getPropertyPanelHistoryActions from './actions/propertyPanelHistoryActions';
import getBlockInteractionActions from './actions/blockInteractionActions';
import getBlockLayoutActions from './actions/blockLayoutActions';
import getPageVariableActions from './actions/getPageVariableActions';
import getPageFunctionActions from './actions/getPageFunctionActions';
import getIncrementVariableCounter from './actions/incrementVariableCounter';
import getIncrementFunctionCounter from './actions/incrementFunctionCounter';
import getBlockPropertyCounterAction from './actions/blockPropertyCounterAction';
import getSetPageHasUnsavedChanges from './actions/setPageHasUnsavedChanges';
import getIncrementPageEventCounter from './actions/incrementPageEventCounter';
import getUpdateInterfacePageAction from './actions/updateInterfacePageAction';
import getUpdateInterfaceRecordAction from './actions/updateInterfaceRecordAction';
import getInsertDependenciesAction from './actions/insertDependenciesAction';
import getSetPageSettingDetailsAction from './actions/setPageSettingDetailsAction';
import getDeviceSwitchAction from './actions/deviceSwitchAction';
import getClearTouchedBlocks from './actions/clearTouchedBlocks';
import getClearTouchedJavascriptEntities from './actions/clearTouchedJavascriptEntities';
import { getUpdateBlockForActiveDeviceAction } from './actions/updateBlock/getUpdateBlockForActiveDeviceAction';
import { getUpdateBlockForAllDevicesAction } from './actions/updateBlock/getUpdateBlockForAllDevicesAction';
import getCreateCompositeBlockAction from './actions/createCompositeBlock';
import getSetInterfacePageElAction from './actions/getSetInterfacePageElAction';
import getSetPathToAddComponentPlaceholderAction from './actions/getSetPathToAddComponentPlaceholderAction';
import getMarkInterfaceRecordDirty from './actions/getMarkInterfaceRecordDirty';

export const createInterfaceStore = (
  initial: InterfaceStoreStateInitialValue,
  additionalArgs: AdditionalArgs,
) => {
  const {
    registry,
    setBlockState,
    updateBlockState,
    setPageVariableState,
    dataSourceExists,
    setBlocksState,
    getDeviceDetails,
    handleDeviceVariantChange,
    resetBlockRefs,
    onChangeActiveBlockId,
    setDependencies,
    setPageFunctionState,
    getDataSourceRecordsStore,
  } = additionalArgs;

  const initializer: StateCreator<InterfaceStoreType> = (set, get) => {
    const args = {
      set,
      get,
      registry,
      setBlockState,
      updateBlockState,
      setPageVariableState,
      dataSourceExists,
      setBlocksState,
      getDeviceDetails,
      handleDeviceVariantChange,
      resetBlockRefs,
      onChangeActiveBlockId,
      setDependencies,
      setPageFunctionState,
      getDataSourceRecordsStore,
    };

    const state: InterfaceStoreState = {
      hoverBlockId: null,
      pathToAddComponentPlaceholder: null,
      copiedBlockDetails: null,
      propertyPanel: {
        history: [
          {
            blockId: ROOT_ID,
            viewDetails: {
              viewType: 'BLOCK_DETAILS',
              data: undefined,
            },
          },
        ],
      },
      touchedBlocks: new Set(),
      touchedJavascriptEntities: new Set(),
      dirtyPages: new Set(),
      isInterfaceRecordDirty: false,
      pageSettingDetails: null,
      ...initial,
    };

    return {
      ...state,
      actions: {
        incrementBlockCounter: getIncrementBlockCounter(args),
        incrementVariableCounter: getIncrementVariableCounter(args),
        incrementFunctionCounter: getIncrementFunctionCounter(args),
        setActiveBlockId: getSetActiveBlockIdAction(args),
        setPathToAddComponentPlaceholder: getSetPathToAddComponentPlaceholderAction(args),
        setPageSettingDetails: getSetPageSettingDetailsAction(args),
        setHoverBlockId: getSetHoverBlockIdAction(args),
        createBlock: getCreateBlockAction(args),
        insertBlock: getInsertBlockAction(args),
        updateBlock: {
          forAllDevices: getUpdateBlockForAllDevicesAction(args),
          forActiveDevice: getUpdateBlockForActiveDeviceAction(args),
        },
        removeBlock: getRemoveBlockAction(args),
        deleteBlock: getDeleteBlockAction(args),
        moveBlock: getMoveBlockAction(args),
        reorderBlock: getReorderBlockAction(args),
        removeSlotInBlock: getRemoveSlotInBlock(args),
        setMode: (mode) => set({ mode }),
        pageVariableActions: getPageVariableActions(args),
        pageFunctionActions: getPageFunctionActions(args),
        propertyPanelHistoryActions: getPropertyPanelHistoryActions(args),
        blockInteractionActions: getBlockInteractionActions(args),
        blockLayoutActions: getBlockLayoutActions(args),
        blockPropertyCounterAction: getBlockPropertyCounterAction(args),
        setPageHasUnsavedChanges: getSetPageHasUnsavedChanges(args),
        incrementPageEventCounter: getIncrementPageEventCounter(args),
        updateInterfacePageAction: getUpdateInterfacePageAction(args),
        updateInterfaceRecordAction: getUpdateInterfaceRecordAction(args),
        createCompositeBlock: getCreateCompositeBlockAction(args),
        insertDependencies: getInsertDependenciesAction(args),
        onDeviceSwitch: getDeviceSwitchAction(args),
        clearTouchedBlocks: getClearTouchedBlocks(args),
        setCopiedBlockDetails: (copiedBlockDetails) => set({ copiedBlockDetails }),
        clearTouchedJavascriptEntities: getClearTouchedJavascriptEntities(args),
        toggleInteractiveMode: () =>
          set((prevState) => ({ isInteractiveModeEnabled: !prevState.isInteractiveModeEnabled })),
        setInterfacePageEl: getSetInterfacePageElAction(args),
        markInterfaceRecordDirty: getMarkInterfaceRecordDirty(args),
      },
    };
  };

  return create<InterfaceStoreType>()(initializer);
};
