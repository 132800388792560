import Stack from '@unifyapps/ui/_components/Stack';
import { Loader } from '@unifyapps/ui/components/Loader';
import DelayRender from '../DelayRender';

export default function AsyncViewLoader() {
  return (
    <DelayRender delay={50}>
      <Stack alignItems="center" className="size-full" justifyContent="center">
        <Loader size="sm" variant="arc" />
      </Stack>
    </DelayRender>
  );
}
