import { create } from 'zustand';
import type { DataSourceEntity } from '@unifyapps/defs/types/dataSource';
import type { Schema } from '@unifyapps/network/generated/models/schema';
import createObjectSetters from '../../../utils/setters/createObjectSetters';
import type {
  UnsavedDataSource,
  DataSourceRecordStoreState,
  DataSourceRecordStoreType,
} from './types';

export const createDataSourceRecordStore = (initial?: Partial<DataSourceRecordStoreState>) =>
  create<DataSourceRecordStoreType>((set) => {
    const {
      setMultiple: setDataSources,
      remove: removeDataSource,
      setSingle: setDataSource,
    } = createObjectSetters<DataSourceRecordStoreState, 'dataSources', DataSourceEntity>(
      'dataSources',
      set,
    );

    const { setMultiple: setDataSourceSchemas } = createObjectSetters<
      DataSourceRecordStoreState,
      'dataSourceSchemas',
      Schema
    >('dataSourceSchemas', set);

    const {
      setMultiple: setUnsavedDataSources,
      setSingle: setUnsavedDataSource,
      remove: removeUnsavedDataSource,
    } = createObjectSetters<DataSourceRecordStoreState, 'unsavedDataSources', UnsavedDataSource>(
      'unsavedDataSources',
      set,
    );

    const storeObject: DataSourceRecordStoreType = {
      dataSources: {},
      unsavedDataSources: {},
      dataSourceSchemas: {},
      ...initial,
      actions: {
        setDataSources,
        setDataSource,
        removeDataSource,

        setDataSourceSchemas,

        setUnsavedDataSources,
        setUnsavedDataSource,
        removeUnsavedDataSource,
      },
    };

    return storeObject;
  });
