import { useState, useEffect } from 'react';
import { EMPTY_OBJECT } from '../../../consts/empty';
import { safeJsonParse } from '../../../utils/json';
import { SessionStorageKeys } from '../../../consts/sessionStorageKeys';

function useInterfaceSessionStorage({ interfaceId }: { interfaceId?: string }) {
  const [interfaceSessionStorage, setInterfaceSessionStorage] = useState<Record<string, unknown>>();

  useEffect(() => {
    //fetching and setting the interface session storage from the session storage
    //as side effect to make sure app should return HTML content from the server
    const stringifiedInterfaceSessionStorage = sessionStorage.getItem(
      SessionStorageKeys.Interfaces,
    );

    const parsedInterfaceSessionStorage = stringifiedInterfaceSessionStorage
      ? safeJsonParse<Record<string, unknown>>(stringifiedInterfaceSessionStorage, {})
      : (EMPTY_OBJECT as Record<string, unknown>);

    if (interfaceId) {
      setInterfaceSessionStorage(
        parsedInterfaceSessionStorage[interfaceId] as Record<string, unknown>,
      );
    }
  }, [interfaceId]);

  return {
    interfaceSessionStorage,
  };
}

export default useInterfaceSessionStorage;
