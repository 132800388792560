import type { InterfaceStoreStateGetterAndSetter } from '../types';

const getSetPageSettingDetailsAction =
  ({ set }: InterfaceStoreStateGetterAndSetter) =>
  (
    pageSettingsParams: {
      show: boolean;
      interfacePageId: string;
      source: 'root' | 'settings';
    } | null,
  ) => {
    set({
      pageSettingDetails: pageSettingsParams,
      activeBlockId: null,
    });
  };

export default getSetPageSettingDetailsAction;
