/* eslint-disable @typescript-eslint/no-extraneous-class -- needed*/
import type { DevicesOverrides, InterfacePageEntity } from '@unifyapps/defs/types/page';
import type { DeviceVariantType } from '@unifyapps/defs/types/deviceVariant';
import type { BlockId } from '@unifyapps/defs/types/block';
import type { Delta } from '@unifyapps/defs/types/page/deviceOverrides';

class InterfacePageHelper {
  static entity = 'e_component';

  static getName(interfacePage: InterfacePageEntity) {
    return interfacePage.properties.name;
  }

  static getId(interfacePage: InterfacePageEntity) {
    return interfacePage.id;
  }

  static getDeviceBlocks(interfacePage: InterfacePageEntity, device: DeviceVariantType) {
    return interfacePage.properties.devices?.[device]?.blocks;
  }

  static getDeviceBlock(
    interfacePage: InterfacePageEntity,
    device: DeviceVariantType,
    blockId: BlockId,
  ) {
    if (!blockId) {
      return undefined;
    }

    return interfacePage.properties.devices?.[device]?.blocks?.[blockId];
  }

  static getBaseDeviceBlocks(interfacePage: InterfacePageEntity) {
    return interfacePage.properties.blocks;
  }

  static getBaseDeviceBlock(interfacePage: InterfacePageEntity, blockId: BlockId) {
    if (!blockId) {
      return undefined;
    }
    return interfacePage.properties.blocks?.[blockId];
  }

  static getBlockDiffInDevice(
    interfacePage: InterfacePageEntity,
    device: DeviceVariantType,
    blockId: BlockId,
  ): Delta | undefined {
    if (!blockId) {
      return undefined;
    }

    return interfacePage.properties.devices?.[device]?.diffs?.blocks?.[blockId];
  }

  static getDeviceOverrides(
    interfacePage: InterfacePageEntity,
    device: DeviceVariantType,
  ): DevicesOverrides['desktop'] {
    return interfacePage.properties.devices?.[device];
  }

  static getDeviceBlocksDiff(
    interfacePage: InterfacePageEntity,
    device: DeviceVariantType,
  ): Record<string, Delta> | undefined {
    return interfacePage.properties.devices?.[device]?.diffs?.blocks;
  }

  static getComponentType(interfacePage: InterfacePageEntity) {
    return interfacePage.properties.componentType;
  }
}

export default InterfacePageHelper;
