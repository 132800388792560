import type { StoreApi } from 'zustand/vanilla';
import type { GlobalStateStore, DependenciesGraph, EntityDependency } from '../types';

const getIntraDependenciesActions = (set: StoreApi<GlobalStateStore>['setState']) => {
  const setIntraDependencies = ({
    dependencyGraph,
    key,
  }: {
    key: 'dataSources';
    dependencyGraph: DependenciesGraph;
  }) => {
    set((state) => {
      return {
        ...state,
        intraDependencies: {
          ...state.intraDependencies,
          [key]: dependencyGraph,
        },
      };
    });
  };

  const setIntraDependenciesSingle = ({
    dependencies,
    entityId,
    key,
  }: {
    key: 'dataSources';
    entityId: string;
    dependencies: EntityDependency[];
  }) => {
    set((state) => {
      return {
        ...state,
        intraDependencies: {
          ...state.intraDependencies,
          [key]: {
            ...state.intraDependencies[key],
            [entityId]: dependencies,
          },
        },
      };
    });
  };

  const removeInterDependencies = ({ key, entityId }: { key: 'dataSources'; entityId: string }) => {
    set((state) => {
      return {
        ...state,
        intraDependencies: {
          ...state.intraDependencies,
          [key]: {
            ...state.intraDependencies[key],
            [entityId]: undefined,
          },
        },
      };
    });
  };

  return { setIntraDependencies, setIntraDependenciesSingle, removeInterDependencies };
};

export default getIntraDependenciesActions;
