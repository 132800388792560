import type { BlockType } from '../../types/block';
import type { DataTableComponentType } from './DataTable/types';

export enum PredefinedColumns {
  Spacer = 'mrt-row-spacer',
  Select = 'mrt-row-select',
  Expand = 'mrt-row-expand',
  ActionMenu = 'mrt-row-actions',
  Action = 'row-actions',
}

export const PredefinedColumnSet = new Set<string>(Object.values(PredefinedColumns));

export enum RowSelection {
  None = 'NONE',
  Single = 'SINGLE',
  Multiple = 'MULTI',
}

export const getIsTableBlock = (block: BlockType): block is BlockType<DataTableComponentType> => {
  return (
    block.component.componentType === 'Table' || block.component.componentType === 'NestedTable'
  );
};
