import type { UserPermissions, UserPermissionsMap } from '../types';

export const adaptCurrentUserPermissions = (
  currentPermissions: UserPermissions,
): UserPermissionsMap =>
  currentPermissions.reduce<UserPermissionsMap>((acc, { entityType, permissions }) => {
    const permissionsMap: Record<string, true> = {};

    if (permissions) {
      permissions.forEach((permission) => {
        permissionsMap[permission] = true;
      });
    }

    acc[entityType] = permissionsMap;
    return acc;
  }, {});
