import type { StoreApi } from 'zustand';
import DataSourceHelper from '../../../helper/DataSourceHelper';
import { DependencyFlowStatus } from '../types';
import type {
  ManuallyTriggeredDataSourcesSetterType,
  GlobalStateStore,
  GlobalStateStoreType,
} from '../types';
import DependencyFlowHelper from '../helpers/DependencyFlowHelper';
import type { DataSourceRecordStoreState } from '../../DataSourceRecordStore';
import getIntraDependenciesActions from './intraDependenciesActions';
import getDependencyFlowActions from './dependencyFlowActions';

/**
 * these actions are business login driven, when you need an action in store related to data source but it is not a simple setter or getter
 * add a new action here and use it in your component
 */
const getDataSourceActions = ({
  get,
  set,
  removeDataSourceState,
  removeManuallyTriggeredDataSources,
  setPreviewTriggeredDataSource,
  getDataSourceRecordsStore,
  setManuallyTriggeredDataSource,
}: {
  set: StoreApi<GlobalStateStore>['setState'];
  get: StoreApi<GlobalStateStore>['getState'];
  getDataSourceRecordsStore: StoreApi<DataSourceRecordStoreState>['getState'];
  removeDataSourceState: (id: string) => void;
  removeManuallyTriggeredDataSources: (ids: string[]) => void;
  setPreviewTriggeredDataSource: (id: string, value: boolean) => void;
  setManuallyTriggeredDataSource: ManuallyTriggeredDataSourcesSetterType['setSingle'];
}) => {
  const { setIntraDependencies, setIntraDependenciesSingle, removeInterDependencies } =
    getIntraDependenciesActions(set);

  const { setEntitiesDependencyFlow, setEntityIdDependencyFlow, removeEntityIdDependencyFlow } =
    getDependencyFlowActions(set, get);

  const dataSourceActions: GlobalStateStoreType['actions']['datasource'] = {
    triggerManualDataSource: (id, value) => {
      const intraDependencies = get().intraDependencies.dataSources;
      const dependencyFlow = get().dependencyFlowManager.dataSources;
      const completeDatasourceDependencyFlow = DependencyFlowHelper.getDependencyFlowStatus({
        dependencyFlowInstance: dependencyFlow,
        entities: {
          [value.dataSourceId]: {
            status: DependencyFlowStatus.Staging,
          },
        },
        intraDependencies,
      });

      // remove id from completeDatasourceDependencyFlow
      const { [value.dataSourceId]: _, ...rest } = completeDatasourceDependencyFlow;

      setEntitiesDependencyFlow({
        entities: rest,
        key: 'dataSources',
      });

      setManuallyTriggeredDataSource(id, value);
    },
    previewDataSource: (id, value) => {
      if (!value) {
        return setPreviewTriggeredDataSource(id, value);
      }

      // we are building a dependency graph because we want to know the latest dependecies of this data source when the preview is triggered
      const intraDependencies = DataSourceHelper.getPreviewDataSourceTenativeDependencies(
        id,
        getDataSourceRecordsStore,
      );

      const dependencyFlow = get().dependencyFlowManager.dataSources;

      // now we have put all the data sources in to staging which would be required to fullfill the preview input
      const completeDatasourceDependencyFlow = DependencyFlowHelper.getDependencyFlowStatus({
        dependencyFlowInstance: dependencyFlow,
        entities: {
          [id]: {
            status: DependencyFlowStatus.Staging,
          },
        },
        intraDependencies,
      });

      // remove id from completeDatasourceDependencyFlow
      const { [id]: _, ...rest } = completeDatasourceDependencyFlow;
      setEntitiesDependencyFlow({
        entities: rest,
        key: 'dataSources',
      });
      setPreviewTriggeredDataSource(id, value);
    },
    removeDataSourceFromStore: (id: string) => {
      // TODO: check for delete data source
      removeDataSourceState(id);
      removeEntityIdDependencyFlow({
        entityId: id,
        key: 'dataSources',
      });
      removeInterDependencies({
        entityId: id,
        key: 'dataSources',
      });
      removeManuallyTriggeredDataSources([id]);
    },
    addDependencyFlow: (entityId, status) => {
      setEntityIdDependencyFlow({
        entityId,
        key: 'dataSources',
        status,
      });
    },
    addIntraDependencies: (entities) => {
      setIntraDependencies({
        dependencyGraph: entities,
        key: 'dataSources',
      });
    },
    addDependencyFlows: (entities) => {
      setEntitiesDependencyFlow({
        entities,
        key: 'dataSources',
      });
    },
    addIntraDependency: (entityId, dependencies) => {
      setIntraDependenciesSingle({
        entityId,
        dependencies,
        key: 'dataSources',
      });

      // now that dependecies are changed, check if this datasource, is non staging,
      const dependencyFlow = get().dependencyFlowManager.dataSources;
      if (!DependencyFlowHelper.isDependencyStaging(dependencyFlow, entityId)) {
        const intraDependencies = get().intraDependencies.dataSources;
        // then we will fitler out all the dependecies that are in unstaged, and put them to staging to fetch their data,
        // intent is to get all the dependecies that are unstaged
        const completeDatasourceDependencyFlow = DependencyFlowHelper.getDependencyFlowStatus({
          dependencyFlowInstance: dependencyFlow,
          entities: {
            [entityId]: {
              status: DependencyFlowStatus.Staging,
            },
          },
          intraDependencies,
        });
        // remove id from completeDatasourceDependencyFlow because it adds itself
        const { [entityId]: _, ...rest } = completeDatasourceDependencyFlow;

        // because this datasource is automatic, after fetching data from new dependecies, it will automatically refetch itself
        setEntitiesDependencyFlow({
          entities: rest,
          key: 'dataSources',
        });
      }
    },
  };

  return dataSourceActions;
};

export default getDataSourceActions;
