import type { Appearance, ContainerComponentType } from '@unifyapps/defs/blocks/Container/types';
import { invariant } from 'ts-invariant';
import type { BlockIds, BlockType } from '@unifyapps/defs/types/block';
import { NEW_BLOCK_ID } from '../../../const';
import type { InterfaceStoreStateGetterAndSetter } from '../types';
import { updateBlockStateByActiveDevice } from '../utils/updateBlockStateByActiveDevice';

export type BlockLayoutActionProps = {
  operation: 'UPDATE_LAYOUT';
  payload: {
    variant: Appearance['variant'];
  };
};

type ContainerBlockType = Omit<BlockType, 'component'> & { component: ContainerComponentType };

function fitBlockIdsIntoSlotIds(blockIds: BlockIds, slotIds: BlockIds) {
  const result = [...slotIds];
  let blockIndex = 0;

  // Fit blockIds into slotIds
  for (let i = 0; i < result.length; i++) {
    if (blockIndex < blockIds.length) {
      result[i] = blockIds[blockIndex];
      blockIndex++;
    } else {
      result[i] = NEW_BLOCK_ID;
    }
  }

  // todo: add support when blockIds.length > slotIds.length i.e. do not drop blockIds

  return result;
}

const getBlockLayoutActions =
  (storeArgs: InterfaceStoreStateGetterAndSetter) => (args: BlockLayoutActionProps) => {
    const { get, set } = storeArgs;
    const { activeBlockId, activeInterfacePageId } = get();
    const { operation } = args;

    invariant(activeBlockId, `BlockId not found in getBlockLayoutActions for ${operation}`);
    const activeBlock =
      get().interfacePages[activeInterfacePageId].properties.blocks?.[activeBlockId];
    invariant(activeBlock, `Block not found in getBlockLayoutActions for ${operation}`);

    switch (operation) {
      case 'UPDATE_LAYOUT': {
        invariant(
          activeBlock.component.componentType === 'Container',
          'Update Layout is supported only for Container block',
        );

        const { variant } = args.payload;

        get().actions.updateBlock.forAllDevices({
          blockId: activeBlockId,
          updateBlock: (draft) => {
            const draftBlock = draft as ContainerBlockType;
            draftBlock.component.appearance.variant = variant;

            const numberOfSlotsToRenderInto = variant.split('_').length;
            const blockIds = draftBlock.component.content.blockIds ?? [];
            const slotIds = Array.from({ length: numberOfSlotsToRenderInto }, () => NEW_BLOCK_ID);
            // ASK AS: We aren't deleting the blocks?
            const updatedBlockIds = fitBlockIdsIntoSlotIds(blockIds, slotIds);
            draftBlock.component.content.blockIds = updatedBlockIds;
          },
        });

        updateBlockStateByActiveDevice({
          blockId: activeBlockId,
          storeArgs,
        });

        break;
      }
      default:
        break;
    }
  };

export default getBlockLayoutActions;
