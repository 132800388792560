/**
 * Defines an action to be performed in the UI, including any parameters required to execute the action.
 */

import type { ConditionEvaluationObject } from '../conditionEvaluationObject';

export enum ActionType {
  ShowNotification = 'showNotification',
  Navigate = 'navigate',
  NavigateBack = 'navigateBack',
  EvalJS = 'evalJS',
  ExportData = 'exportData',
  CreateRecord = 'createRecord',
  ViewRecord = 'viewRecord',
  EditRecord = 'editRecord',
  DeleteRecord = 'deleteRecord',
  CloneRecord = 'cloneRecord',
  ExportRecord = 'exportRecord',
  EditPartialRecord = 'editPartialRecord',
  Login = 'login',
  SetPageVariable = 'setPageVariable',
  EmitPageEvent = 'emitPageEvent',
  ControlBlockMethod = 'controlBlockMethod',
  ControlDataSource = 'controlDataSource',
  ControlModal = 'controlModal',
  ControlDrawer = 'controlDrawer',
  ShareRecord = 'shareRecord',
  SubmitForm = 'submitForm',
  NavigateToPage = 'navigateToPage',
  ImportObjects = 'importObjects',
  SetInterfaceSessionStorage = 'setInterfaceSessionStorage',
  ExecuteScript = 'executeScript',
  RestoreVersion = 'restoreVersion',
  CopyToClipboard = 'copyToClipboard',
}

export interface Action {
  // should be mandatory but not done because of backward compatibility
  id?: string;
  /**
   * The type or name of the action to be performed. This should correspond to a predefined set of actions in the application.
   */
  actionType?: ActionType;

  /**
   * The label to display for the action in the UI.
   */
  label?: string;
  /**
   * The name of the icon to display for the action in the UI. This should correspond to a predefined set of icons in the application.
   */
  icon?: string;
  /**
   * A brief description of what the action does, optional but helpful for understanding the action's purpose.
   */
  description?: string;
  /**
   * A message to display to the user to confirm the action before it is executed. This is useful for potentially destructive actions.
   */
  confirmation?: string;
  /**
   * Whether the action should be visible or not.
   */
  visible?: boolean;
  /**
   * The variant of UI element that is used in action
   */
  variant?: string;
  /**
   * Whether the action should be disabled or not.
   */
  disabled?: boolean;
  /**
   * An object containing additional data to be passed to the action handler. This can be dynamically defined based on the actionType.
   */
  payload?: {
    /**
     * The type of object to which the action applies. This should correspond to a predefined set of object types in the application.
     */
    objectType?: string;
    /**
     * The ID of the record to which the action applies.
     */
    recordId?: string;
    [k: string]: unknown;
  };

  enabled?: string | boolean;

  onSuccessActions?: Action[];
  onErrorActions?: Action[];
  /**
   * The condition under which the action should be triggered.
   */
  runCondition?: ConditionEvaluationObject;
  /**
   * The delay in milliseconds before the action is triggered.
   */
  delayDuration?: number;
  /**
   * For timer block, to run event each time after a specific time interval
   */
  timeInterval?: number;
}

export enum CallbackActionType {
  SUCCESS = 'success',
  FAILURE = 'failure',
}
