import type { StoreApi } from 'zustand/vanilla';
import type { UAEntityTypes } from '@unifyapps/defs/types/entities';
import type { GlobalStateStore } from '../types';

export type InterfaceVersionUpdatesActionProps =
  | {
      operation: 'HIDE_VERSION_ERROR';
      payload: undefined;
    }
  | {
      operation: 'ADD_STALE_ENTITIES';
      payload: {
        assetClass: UAEntityTypes.Interface | UAEntityTypes.Component | UAEntityTypes.DataSource;
        assetId: string;
      };
    }
  | {
      operation: 'REMOVE_STALE_ENTITIES';
      payload: {
        entityDetails: {
          assetClass: UAEntityTypes.Interface | UAEntityTypes.Component | UAEntityTypes.DataSource;
          assetId: string;
        }[];
      };
    }
  | {
      operation: 'ADD_TO_IGNORED_ENTITIES';
      payload: {
        entityDetails: {
          assetClass: UAEntityTypes.Interface | UAEntityTypes.Component | UAEntityTypes.DataSource;
          assetId: string;
        }[];
      };
    }
  | {
      operation: 'REMOVE_FROM_INGORED_ENTITIES';
      payload: {
        entityDetails: {
          assetClass: UAEntityTypes.Interface | UAEntityTypes.Component | UAEntityTypes.DataSource;
          assetId: string;
        }[];
      };
    }
  | {
      operation: 'CLEAN_STALE_ENTITIES';
      payload: object;
    };

const getInterfaceVersionUpdatesAction =
  (set: StoreApi<GlobalStateStore>['setState']) =>
  ({ operation, payload }: InterfaceVersionUpdatesActionProps) => {
    switch (operation) {
      case 'ADD_STALE_ENTITIES': {
        set((state) => ({
          ...state,
          interfaceVersionUpdates: {
            showVersionError: true,
            outdatedEntities: [
              ...state.interfaceVersionUpdates.outdatedEntities,
              { assetClass: payload.assetClass, assetId: payload.assetId },
            ],
            entitiesToIgnore: state.interfaceVersionUpdates.entitiesToIgnore,
          },
        }));

        break;
      }
      case 'REMOVE_STALE_ENTITIES': {
        set((state) => {
          const outdatedEntities = state.interfaceVersionUpdates.outdatedEntities.filter(
            (entity) =>
              !payload.entityDetails.some(
                (entityDetail) =>
                  entityDetail.assetClass === entity.assetClass &&
                  entityDetail.assetId === entity.assetId,
              ),
          );

          return {
            ...state,
            interfaceVersionUpdates: {
              showVersionError: outdatedEntities.length > 0,
              outdatedEntities,
              entitiesToIgnore: state.interfaceVersionUpdates.entitiesToIgnore,
            },
          };
        });
        break;
      }
      case 'CLEAN_STALE_ENTITIES': {
        set((state) => ({
          ...state,
          interfaceVersionUpdates: {
            showVersionError: false,
            outdatedEntities: [],
            entitiesToIgnore: state.interfaceVersionUpdates.entitiesToIgnore,
          },
        }));
        break;
      }
      case 'HIDE_VERSION_ERROR': {
        set((state) => ({
          ...state,
          interfaceVersionUpdates: {
            ...state.interfaceVersionUpdates,
            showVersionError: false,
          },
        }));
        break;
      }
      case 'ADD_TO_IGNORED_ENTITIES': {
        set((state) => ({
          ...state,
          interfaceVersionUpdates: {
            ...state.interfaceVersionUpdates,
            entitiesToIgnore: [
              ...state.interfaceVersionUpdates.entitiesToIgnore,
              ...payload.entityDetails,
            ],
          },
        }));
        break;
      }
      case 'REMOVE_FROM_INGORED_ENTITIES': {
        set((state) => {
          const entitiesToIgnore = state.interfaceVersionUpdates.entitiesToIgnore.filter(
            (entity) =>
              !payload.entityDetails.some(
                (entityDetail) =>
                  entityDetail.assetClass === entity.assetClass &&
                  entityDetail.assetId === entity.assetId,
              ),
          );

          return {
            ...state,
            interfaceVersionUpdates: {
              ...state.interfaceVersionUpdates,
              entitiesToIgnore,
            },
          };
        });
        break;
      }
    }
  };

export default getInterfaceVersionUpdatesAction;
