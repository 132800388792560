import { produce } from 'immer';
import _set from 'lodash/set';
import type { InterfaceStoreStateGetterAndSetter } from '../types';

const getIncrementBlockCounter =
  ({ get, set }: InterfaceStoreStateGetterAndSetter) =>
  (componentType: string) => {
    const currentPageId = get().activeInterfacePageId;
    const count =
      get().interfacePages[currentPageId].properties.metadata?._blockCounter?.[componentType];
    const newCount = (count ?? 0) + 1;

    set({
      interfacePages: produce(get().interfacePages, (draftPages) => {
        _set(
          draftPages,
          [currentPageId, 'properties', 'metadata', '_blockCounter', componentType],
          newCount,
        );
      }),
    });

    return newCount;
  };

export default getIncrementBlockCounter;
