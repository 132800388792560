import type { StoreApi } from 'zustand/vanilla';
import { produce } from 'immer';
import _set from 'lodash/set';
import _isString from 'lodash/isString';
import type { GlobalStateStore } from '../types';
import { SessionStorageKeys } from '../../../../consts/sessionStorageKeys';
import { safeJsonParse } from '../../../../utils/json';

export type InterfaceSessionStorageActionProps = {
  operation: 'SET_IN';
  payload: {
    path: string | string[] | undefined;
    value: unknown;
    interfaceId: string;
  };
};

const getInterfaceSessionStorageAction =
  (set: StoreApi<GlobalStateStore>['setState']) =>
  ({ operation, payload }: InterfaceSessionStorageActionProps) => {
    switch (operation) {
      case 'SET_IN': {
        const { interfaceId, path: propertyPath } = payload;

        const stringifiedInterfaceSessionStorage = sessionStorage.getItem(
          SessionStorageKeys.Interfaces,
        );

        const sessionStorageValue = stringifiedInterfaceSessionStorage
          ? safeJsonParse(stringifiedInterfaceSessionStorage, {})
          : {};

        const updatedSessionStorage = produce(sessionStorageValue, (draft) => {
          const path = _isString(propertyPath) ? [propertyPath] : propertyPath;

          _set(draft, [interfaceId, ...(path ?? [])], payload.value);
        });

        sessionStorage.setItem(
          SessionStorageKeys.Interfaces,
          JSON.stringify(updatedSessionStorage),
        );
        set((state) => {
          return {
            ...state,
            interfaceSessionStorage: updatedSessionStorage[interfaceId] as Record<string, unknown>,
          };
        });
      }
    }
  };

export default getInterfaceSessionStorageAction;
