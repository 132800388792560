/* eslint-disable @typescript-eslint/no-extraneous-class -- needed*/
import { produce } from 'immer';
import { UAEntityTypes } from '@unifyapps/defs/types/entities';
import { RunBehaviourEnums } from '@unifyapps/defs/types/dataSource';
import type { DataSourceEntity, DataSourceCallbacks } from '@unifyapps/defs/types/dataSource';
import type { StoreApi } from 'zustand';
import type {
  DataSourceRecordStoreState,
  UnsavedDataSource,
} from '../stores/DataSourceRecordStore';
import { buildIntraEntityDependencyGraph } from '../stores/GlobalStateStore/utils/buildDependencyGraph';

class DataSourceHelper {
  static entity = UAEntityTypes.DataSource;

  static name(dataSource: UnsavedDataSource) {
    return dataSource.properties.name;
  }

  static properties(dataSource: UnsavedDataSource) {
    return dataSource.properties;
  }

  static inputs(dataSource: UnsavedDataSource) {
    return dataSource.properties.inputs;
  }

  static callbacks(dataSource: UnsavedDataSource) {
    return dataSource.properties.callbacks;
  }

  static id(dataSource: UnsavedDataSource) {
    return dataSource.id;
  }

  static updateInputs(dataSource: UnsavedDataSource, input: Record<string, unknown>) {
    return produce(dataSource, (draft) => {
      draft.properties.inputs = input as unknown as UnsavedDataSource['properties']['inputs'];
    });
  }

  static updateAdvancedOptions(
    dataSource: UnsavedDataSource,
    advancedOptions: UnsavedDataSource['properties']['advancedOptions'],
  ) {
    return produce(dataSource, (draft) => {
      draft.properties.advancedOptions = advancedOptions;
    });
  }

  static updateRunBehavior(dataSource: UnsavedDataSource, runBehavior: RunBehaviourEnums) {
    return produce(dataSource, (draft) => {
      if (!draft.properties.advancedOptions) {
        draft.properties.advancedOptions = {};
      }
      draft.properties.advancedOptions.runBehaviour = runBehavior;
    });
  }

  static advancedOptions(dataSource?: UnsavedDataSource) {
    return dataSource?.properties.advancedOptions ?? {};
  }

  static updateCallbacks(dataSource: UnsavedDataSource, updatedCallbacks: DataSourceCallbacks) {
    return produce(dataSource, (draft) => {
      draft.properties.callbacks = updatedCallbacks;
    });
  }

  static context(dataSource: UnsavedDataSource) {
    return dataSource.properties.context ?? {};
  }

  static isLegacyDataSource(dataSource?: UnsavedDataSource) {
    const advancedOptions = DataSourceHelper.advancedOptions(dataSource);
    return advancedOptions.runBehaviour === undefined;
  }

  static isAutomaticDataSource(dataSource?: UnsavedDataSource) {
    const advancedOptions = DataSourceHelper.advancedOptions(dataSource);
    return advancedOptions.runBehaviour === RunBehaviourEnums.AUTOMATIC;
  }

  static isManualDataSource(dataSource?: UnsavedDataSource) {
    const advancedOptions = DataSourceHelper.advancedOptions(dataSource);
    return advancedOptions.runBehaviour === RunBehaviourEnums.MANUAL;
  }

  static isRunQueryOnPageLoad(dataSource?: UnsavedDataSource) {
    const advancedOptions = DataSourceHelper.advancedOptions(dataSource);
    return advancedOptions.timing?.runQueryOnPageLoad ?? false;
  }

  static isApplicationTypeDataSource(dataSource?: UnsavedDataSource) {
    return dataSource?.properties.type === 'APPLICATION';
  }

  static getAutomaticPageLoadDataSources(
    dataSources: Record<string, DataSourceEntity | undefined>,
  ) {
    return Object.values(dataSources)
      .filter((dataSource) => {
        if (DataSourceHelper.isApplicationTypeDataSource(dataSource)) {
          return (
            DataSourceHelper.isRunQueryOnPageLoad(dataSource) &&
            (DataSourceHelper.isAutomaticDataSource(dataSource) ||
              DataSourceHelper.isLegacyDataSource(dataSource))
          );
        }

        return false;
      })
      .filter((ds): ds is DataSourceEntity => Boolean(ds));
  }

  static getManualPageLoadDataSources(dataSources: Record<string, DataSourceEntity | undefined>) {
    return Object.values(dataSources)
      .filter((dataSource) => {
        if (DataSourceHelper.isApplicationTypeDataSource(dataSource)) {
          return (
            DataSourceHelper.isRunQueryOnPageLoad(dataSource) &&
            DataSourceHelper.isManualDataSource(dataSource)
          );
        }

        return false;
      })
      .filter((ds): ds is DataSourceEntity => Boolean(ds));
  }

  static getPreviewDataSourceTenativeDependencies(
    id: string,
    getDataSourceRecordsStore: StoreApi<DataSourceRecordStoreState>['getState'],
  ) {
    const dataSourceRecords = getDataSourceRecordsStore().dataSources;
    const unsavedDataSources = getDataSourceRecordsStore().unsavedDataSources;
    const allDataSources = {
      ...dataSourceRecords,
      [id]: unsavedDataSources[id] ?? dataSourceRecords[id],
    };

    const intraDependencies = DataSourceHelper.getDataSourceIntraDependencies(allDataSources);
    return intraDependencies;
  }

  static getDataSourceIntraDependencies(
    dataSources: Record<string, DataSourceEntity | UnsavedDataSource | undefined>,
  ) {
    const automaticDataSourcesIds = DataSourceHelper.filterAutomaticDataSourcesIds(dataSources);

    // remove properties.callbacks from the dataSource records because we don't want to include them in the dependency graph
    // reason: because data source referenced in callbacks are not needed for data fetching
    const adaptedDataSources = Object.fromEntries(
      Object.entries(dataSources).map(([key, value]) => {
        if (value) {
          const {
            properties: { callbacks: _, ...rest },
          } = value;
          return [key, rest];
        }
        return [key, value];
      }),
    );

    // for data sources we only pick inputs
    return buildIntraEntityDependencyGraph(adaptedDataSources, automaticDataSourcesIds);
  }

  static filterAutomaticDataSourcesIds(
    dataSources: Record<string, DataSourceEntity | UnsavedDataSource | undefined>,
  ) {
    return Object.values(dataSources)
      .filter(
        (dataSource) =>
          DataSourceHelper.isAutomaticDataSource(dataSource) ||
          DataSourceHelper.isLegacyDataSource(dataSource),
      )
      .map((ds) => ds?.id)
      .filter((id): id is string => Boolean(id));
  }
}

export default DataSourceHelper;
