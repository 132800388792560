'use client';
import { createContext, useContext } from 'react';
import type BlocksRegistry from './BlocksRegistry';

export const RegistryProviderContext = createContext<BlocksRegistry | undefined>(undefined);

export const useRegistryContext = () => {
  const context = useContext(RegistryProviderContext);

  if (!context) {
    throw new Error('useRegistryContext must be used within a RegistryProviderContext');
  }
  return { registry: context };
};
