import _forEach from 'lodash/forEach';
import { getDynamicBindings } from '../../../utils/dynamicBindings';

type Dependency = {
  property: string;
};

type DependencyList = Dependency[];

function findDependencies({
  obj,
  dependencies,
  parentPath = '',
}: {
  obj: unknown;
  dependencies: DependencyList;
  parentPath?: string;
}): DependencyList {
  if (typeof obj === 'object' && obj !== null) {
    for (const prop in obj) {
      const value = (obj as Record<string, unknown>)[prop];
      const currentPath = parentPath ? `${parentPath}.${prop}` : prop;

      if (typeof value === 'object' && value !== null) {
        findDependencies({ obj: value, parentPath: currentPath, dependencies });
      } else if (typeof value === 'string') {
        // use getDynamicBinding because it is the function that gives us the jsSnippets when we are computing in our no-code app
        const { jsSnippets } = getDynamicBindings(value);
        if (jsSnippets.length) {
          jsSnippets.forEach((snippet) => {
            if (snippet) {
              dependencies.push({ property: snippet.trim() });
            }
          });
        }
      }
    }
  }

  return dependencies;
}

export function buildDependencyGraph(
  objects: Record<string, unknown>,
): Record<string, DependencyList | undefined> {
  const dependencyGraph: Record<string, DependencyList> = {};

  for (const key in objects) {
    const dependencies: DependencyList = [];
    findDependencies({ obj: objects[key], dependencies });
    dependencyGraph[key] = dependencies.sort((a, b) => a.property.localeCompare(b.property));
  }

  return dependencyGraph;
}

/**
 *
 * @param objects - the entity of which we need to find intra dependencies
 * @param entityIds - all the entityIds of the entity which are present in the app
 * @returns - the intra dependency graph
 */
export function buildIntraEntityDependencyGraph<K extends string>(
  objects: Record<K, unknown>,
  entityIds: K[],
) {
  const dependencyGraph = buildDependencyGraph(objects);

  Object.keys(dependencyGraph).forEach((key) => {
    const dependencies = dependencyGraph[key];
    const filteredIntraDeps = entityIds.filter((id) =>
      dependencies?.some((dep) => dep.property.includes(id)),
    );
    dependencyGraph[key] = filteredIntraDeps.map((id) => ({ property: id }));
  });

  // remove self dependencies
  _forEach(dependencyGraph, (deps, key) => {
    dependencyGraph[key] = deps?.filter((dep) => dep.property !== key);
  });

  return dependencyGraph;
}
