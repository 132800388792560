import { cva } from 'class-variance-authority';
import type { LoaderSizes, LoaderVariants } from './types';

export const loaderRootVariants = cva('', {
  variants: {
    variant: {
      arc: '',
      circle: '',
    } satisfies Record<LoaderVariants, string>,
    size: {
      xxs: 'size-[16px]',
      xs: 'size-[20px]',
      sm: 'size-[32px]',
      md: 'size-[48px]',
      lg: 'size-[56px]',
      xl: 'size-[64px]',
    } satisfies Record<LoaderSizes, string>,
  },
  compoundVariants: [],
});

export const loaderTrackVariants = cva('', {
  variants: {
    variant: {
      arc: 'stroke-transparent',
      circle: 'stroke-utility-gray-100',
    } satisfies Record<LoaderVariants, string>,
    size: {
      xxs: 'stroke-[2px]',
      xs: 'stroke-[2px]',
      sm: 'stroke-[4px]',
      md: 'stroke-[6px]',
      lg: 'stroke-[6px]',
      xl: 'stroke-[8px]',
    } satisfies Record<LoaderSizes, string>,
  },
  compoundVariants: [],
});

export const loaderProgressVariants = cva('', {
  variants: {
    variant: {
      arc: 'stroke-fg-brand-primary',
      circle: 'stroke-fg-brand-primary',
    } satisfies Record<LoaderVariants, string>,
    size: {
      xxs: 'stroke-[2px]',
      xs: 'stroke-[2px]',
      sm: 'stroke-[4px]',
      md: 'stroke-[6px]',
      lg: 'stroke-[6px]',
      xl: 'stroke-[8px]',
    } satisfies Record<LoaderSizes, string>,
  },
  compoundVariants: [],
});
