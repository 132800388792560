import { deviceTypes } from '@unifyapps/defs/types/deviceVariant';
import type { DeviceVariantType } from '@unifyapps/defs/types/deviceVariant';
import type { InterfacePageEntity, DevicesOverrides } from '@unifyapps/defs/types/page';
import _forEach from 'lodash/forEach';
import { createDraft, finishDraft } from 'immer';
import _isEmpty from 'lodash/isEmpty';
import type { BlockId } from '@unifyapps/defs/types/block';
import InterfacePageHelper from '../../../helper/InterfacePage';
import { applyBlockDiff, createBlockDiff } from './blockDiffUtils';

export const getNextInterfacePage = ({
  currentDevice,
  interfacePage,
  baseDevice,
  touchedBlocks,
}: {
  interfacePage: InterfacePageEntity;
  currentDevice: DeviceVariantType;
  baseDevice: DeviceVariantType;
  touchedBlocks: Set<BlockId>;
}) => {
  const nextInterfacePage = createDraft(interfacePage);

  if (currentDevice === baseDevice) {
    const changedBlocks = Array.from(touchedBlocks);

    _forEach(changedBlocks, (blockId) => {
      if (!blockId) {
        return;
      }

      const baseBlock = InterfacePageHelper.getBaseDeviceBlock(interfacePage, blockId);

      if (!baseBlock) {
        return;
      }

      deviceTypes.forEach((device) => {
        if (device === baseDevice) {
          return;
        }

        const diff = InterfacePageHelper.getBlockDiffInDevice(interfacePage, device, blockId);

        //if diff is not found it means the block is not changes in the non base device
        if (_isEmpty(diff)) {
          return;
        }

        const updatedBlock = applyBlockDiff({
          block: baseBlock,
          diff,
        });

        const updatedDeviceOverrides: DevicesOverrides['desktop'] = {
          ...InterfacePageHelper.getDeviceOverrides(interfacePage, device),
          blocks: {
            ...InterfacePageHelper.getDeviceBlocks(interfacePage, device),
            [blockId]: updatedBlock,
          },
        };

        nextInterfacePage.properties.devices = {
          ...nextInterfacePage.properties.devices,
          [device]: updatedDeviceOverrides,
        };
      });
    });
    // case 1 : If switch to base device
    // case 2 : If switch to non base device
    //just calculate diff from base variant block and save that in the deviceOverrides for that
    //device
  } else {
    const changedBlocks = Array.from(touchedBlocks);

    _forEach(changedBlocks, (blockId) => {
      if (!blockId) {
        return;
      }
      const baseBlock = interfacePage.properties.blocks?.[blockId];
      const nonBaseBlock = interfacePage.properties.devices?.[currentDevice]?.blocks?.[blockId];

      if (!baseBlock || !nonBaseBlock) {
        throw new Error('Block not found while creating diff');
      }

      const diff = createBlockDiff({ baseBlock, nonBaseBlock });

      const updatedDeviceOverrides: DevicesOverrides['desktop'] = {
        blocks: InterfacePageHelper.getDeviceBlocks(interfacePage, currentDevice),
        diffs: {
          blocks: {
            ...InterfacePageHelper.getDeviceBlocksDiff(interfacePage, currentDevice),
            [blockId]: diff,
          },
        },
      };

      nextInterfacePage.properties.devices = {
        ...nextInterfacePage.properties.devices,
        [currentDevice]: updatedDeviceOverrides,
      };
    });
  }

  return finishDraft(nextInterfacePage);
};
