import type { ConditionEvaluationObject } from '../conditionEvaluationObject';
import type { Event } from '../event';
import type { Entity } from '../page/entity';
import type { Query } from '../query';

/**
 * Make sure that this type is in sync with the type defined in the API e_data_source on backend https://qa.unifyapps.com/api/entity-type?entityType=e_data_source
 */
type ContentType = 'none' | 'raw';

type InputLanguage = 'json' | 'xml' | 'text' | 'javascript' | 'html';

type HTTPMethod = 'GET' | 'POST' | 'PUT' | 'DELETE';
export interface HTTPRequest {
  method: HTTPMethod; // HTTP method of the request (e.g., GET, POST, PUT, DELETE)
  url: string; // The URL of the request
  queryParams?: Record<string, string>; // Query parameters of the request
  headers?: Record<string, string>; // HTTP headers of the request
  body?: {
    contentType: ContentType; // The content type of the request body
    inputLanguage?: InputLanguage; // The language of the input text
    input?: string | Record<string, unknown>; // The text or JSON object to send in the request body
  } & (
    | {
        contentType: 'raw';
        inputLanguage: 'json';
        input: Record<string, unknown>; // The JSON object to send in the request body
      }
    | {
        contentType: 'raw';
        inputLanguage: Exclude<InputLanguage, 'json'>;
        input: string; // The text to send in the request body
      }
    | {
        contentType: Exclude<ContentType, 'raw'>;
      }
  );
}

export interface ApiEndpointInputsType {
  apiEndpointId: string;
  httpRequest: HTTPRequest;
}

export interface QueryInputsType {
  payload: Query;
}

export type AutomationInputsType = {
  automationId?: string;
  parameters?: Record<string, unknown>;
};

export type NodeContextType = {
  type?: 'APPLICATION';
  appName?: string;
  resourceName?: string;
  resourceVersion?: number;
  connectionId?: string;
};

export type ApplicationAdvancedOptionsType = {
  /**
   * Automatic or manual run behaviour
   */
  runBehaviour?: RunBehaviourEnums;
  /**
   * condition whent the query will not fire, we send this to react-query after evaluation
   */
  queryDisabled?: ConditionEvaluationObject;
  /**
   * show message in snackbar when query is disabled
   */
  queryDisabledMessage?: string;
  /**
   * refetch the query when the window is refocused (user comes back to tab), default is true
   */
  refetchOnWindowFocus?: boolean;
  timing?: {
    /**
     * execute query as soon as globalstatestore is loaded
     */
    runQueryOnPageLoad?: boolean;
    /**
     * keep running the query after every interval
     */
    runQueryPeriodically?: boolean;
    runQueryPeriodInterval?: number;
  };
};

export type DataSourceCallbacks = {
  onSuccess?: Record<string, Event>;
  onFailure?: Record<string, Event>;
};

export enum RunBehaviourEnums {
  MANUAL = 'manual',
  AUTOMATIC = 'automatic',
}

export type DataSource = {
  interfacePageId: string;
  type: 'QUERY_BY_UA' | 'API_ENDPOINT' | 'APPLICATION';
  name?: string;
  context?: NodeContextType;
  inputs?: Record<string, unknown>;
  advancedOptions?: ApplicationAdvancedOptionsType;
  callbacks?: DataSourceCallbacks;
} & (
  | {
      type: 'APPLICATION';
      inputs?: AutomationInputsType;
      advancedOptions?: ApplicationAdvancedOptionsType;
    }
  | {
      type: 'QUERY_BY_UA';
      inputs?: QueryInputsType;
    }
  | {
      type: 'API_ENDPOINT';
      inputs?: ApiEndpointInputsType;
    }
);

export type DataSourceEntity = Entity<DataSource>;
