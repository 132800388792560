import _set from 'lodash/set';
import _without from 'lodash/without';
import _omit from 'lodash/omit';
import _map from 'lodash/map';
import { invariant } from 'ts-invariant';
import _update from 'lodash/update';
import type { InterfaceStoreStateGetterAndSetter } from '../types';
import type { RemoveBlockContext } from './removeBlock';
import type { InsertBlockContext } from './insertBlock';

export type MoveBlockActionProps = {
  blockId: string;
  insertContext: InsertBlockContext;
  removeContext: RemoveBlockContext;
};

export type MoveBlockActionReturnType = {
  success: boolean;
  blockId: string;
};

const getMoveBlockAction =
  (storeArgs: InterfaceStoreStateGetterAndSetter) =>
  (props: MoveBlockActionProps): MoveBlockActionReturnType => {
    const { blockId, insertContext, removeContext } = props;
    const { get } = storeArgs;
    const state = get();
    const activePageId = state.activeInterfacePageId;
    const activeInterfacePage = state.interfacePages[activePageId];

    invariant(activeInterfacePage, 'getMoveBlockAction: Active interface page not found');

    const blockToMove = activeInterfacePage.properties.blocks?.[blockId];

    invariant(blockToMove, `getMoveBlockAction: Block with id ${blockId} not found`);

    // STEP 1: remove the block from its parent, without changing its state
    const removed = state.actions.removeBlock({ blockId, context: removeContext });

    invariant(
      removed,
      `getMoveBlockAction: Failed to remove blockId ${blockId} with context ${JSON.stringify(removeContext)}`,
    );

    // STEP 2: insert the block in the new parent
    const inserted = state.actions.insertBlock({ blockId, context: insertContext });

    invariant(
      inserted,
      `getMoveBlockAction: Failed to insert blockId ${blockId} with context ${JSON.stringify(insertContext)}`,
    );

    return { success: true, blockId };
  };

export default getMoveBlockAction;
