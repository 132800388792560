import type { BlockId, BlockType } from '@unifyapps/defs/types/block';
import { produce } from 'immer';
import { DeviceVariantType } from '@unifyapps/defs/types/deviceVariant';
import type { DevicesOverrides } from '@unifyapps/defs/types/page';
import { invariant } from 'ts-invariant';
import type { InterfaceEntity } from '@unifyapps/defs/types/interface';
import type { InterfaceStoreStateGetterAndSetter } from '../types';
import { markUnsavedChanges } from './markUnsavedChanges';

export const getBaseDeviceOfThePage = (
  interfaceRecord?: InterfaceEntity | null,
): DeviceVariantType => {
  return interfaceRecord?.properties.deviceDetails?.base ?? DeviceVariantType.DESKTOP;
};

type SetBlockArgs = {
  pageId: string;
  blockId: BlockId;
  block: BlockType;
  updateOnlyInBase?: boolean;
};

export const getSetBlock =
  ({ set, get, getDeviceDetails }: InterfaceStoreStateGetterAndSetter) =>
  ({ block, blockId, pageId }: SetBlockArgs) => {
    if (!blockId) {
      return;
    }

    const { device: currentDevice } = getDeviceDetails();
    const baseDevice = getBaseDeviceOfThePage(get().interfaceRecord);

    if (baseDevice === currentDevice) {
      set((state) => {
        return produce(state, (draftState) => {
          //Step 1: mark pageHasUnsavedChanges true when ever a block is updated
          markUnsavedChanges(pageId, draftState);

          // Step 2: Set the block in the blocks map
          draftState.interfacePages[pageId].properties.blocks = {
            ...draftState.interfacePages[pageId].properties.blocks,
            [blockId]: block,
          };
        });
      });
    } else {
      const baseBlock = get().interfacePages[pageId].properties.blocks?.[blockId];
      if (!baseBlock) {
        invariant(
          'getSetBlock: Base block not found, this is an error, all blocks should be present in base',
          blockId,
        );
        return;
      }

      // When in non-base view, all the updates happen in the device[type].blocks[blockId] path
      set((state) => {
        return produce(state, (draftState) => {
          const updatedDeviceOverrides: DevicesOverrides['desktop'] = {
            blocks: {
              ...draftState.interfacePages[pageId].properties.devices?.[currentDevice]?.blocks,
              [blockId]: block,
            },
          };

          draftState.interfacePages[pageId].properties.devices = {
            ...draftState.interfacePages[pageId].properties.devices,
            [currentDevice]: updatedDeviceOverrides,
          };
        });
      });
    }
  };

type CreateBlockArgs = {
  pageId: string;
  blockId: BlockId;
  block: BlockType;
};

export const getCreateBlock =
  (storeArgs: InterfaceStoreStateGetterAndSetter) =>
  ({ block, blockId, pageId }: CreateBlockArgs) => {
    const { get, set } = storeArgs;

    if (!blockId) {
      return;
    }

    // Step 1: Get the active page id, where the block will be created
    const activePageId = get().activeInterfacePageId;
    if (!activePageId) {
      console.debug('getCreateBlock: No active page found');
      return { success: false, blockId: null };
    }

    set((state) => {
      return produce(state, (draftState) => {
        //Step 1: mark pageHasUnsavedChanges true when ever a block is updated
        markUnsavedChanges(pageId, draftState);

        // Step 2: Set the block in the base variant
        draftState.interfacePages[pageId].properties.blocks = {
          ...draftState.interfacePages[pageId].properties.blocks,
          [blockId]: {
            ...block,
            visibility: {
              value: true,
            },
          },
        };
      });
    });
  };

export const getBulkCreateBlocks =
  (storeArgs: InterfaceStoreStateGetterAndSetter) =>
  ({ blocks, pageId }: { blocks: Record<string, BlockType>; pageId: string }) => {
    const { get, set } = storeArgs;

    // Step 1: Get the active page id, where the block will be created
    const activePageId = get().activeInterfacePageId;
    if (!activePageId) {
      console.debug('getCreateBlock: No active page found');
      return { success: false, blockId: null };
    }

    set((state) => {
      return produce(state, (draftState) => {
        //Step 1: mark pageHasUnsavedChanges true when ever a block is updated
        markUnsavedChanges(pageId, draftState);

        // Step 2: Set the block in the base variant
        draftState.interfacePages[pageId].properties.blocks = {
          ...draftState.interfacePages[pageId].properties.blocks,
          ...blocks,
        };
      });
    });
  };
