import { produce } from 'immer';
import { invariant } from 'ts-invariant';
import type { PageFunction, PageFunctionState } from '@unifyapps/defs/types/pageFunction';
import DataSourceHelper from '../../../helper/DataSourceHelper';
import { FUNCTION_PREFIX } from '../../../const';
import type { InterfaceStoreStateGetterAndSetter } from '../types';
import { getIdWithPrefix } from '../../../../utils/id';
import {
  buildDependencyGraph,
  buildIntraEntityDependencyGraph,
} from '../../GlobalStateStore/utils/buildDependencyGraph';

export type PageFunctionActionProps =
  | {
      operation: 'CREATE';
      payload: {
        functionBody: string;
      };
    }
  | {
      operation: 'DELETE';
      payload: {
        pageFunctionId: string;
      };
    }
  | {
      operation: 'UPDATE';
      payload: {
        pageFunctionId: string;
        updatedPageFunction: PageFunction;
      };
    };

const getPageFunctionActions =
  (storeArgs: InterfaceStoreStateGetterAndSetter) =>
  ({ operation, payload }: PageFunctionActionProps) => {
    const { get, set, setPageFunctionState, setDependencies, getDataSourceRecordsStore } =
      storeArgs;
    const state = get();
    const { incrementFunctionCounter } = state.actions;
    const activePageId = state.activeInterfacePageId;

    const getUsedDataSourceIds = (pageFunction: Omit<PageFunction, 'dataSourceIds'>) => {
      const { id } = pageFunction;
      const dataSources = getDataSourceRecordsStore().dataSources;

      const dataSourceDependencyGraph = buildIntraEntityDependencyGraph(
        {
          [id]: pageFunction,
        },
        DataSourceHelper.filterAutomaticDataSourcesIds(dataSources),
      );
      const dataSourceIds = (dataSourceDependencyGraph[id] ?? []).map((dep) => dep.property);
      return dataSourceIds;
    };

    switch (operation) {
      case 'CREATE': {
        const newFunctionId = getIdWithPrefix(FUNCTION_PREFIX);
        const newState = produce(state, (draftState) => {
          const currentPage = draftState.interfacePages[activePageId];
          invariant(currentPage, `Page with id ${activePageId} not found`);

          const count = currentPage.properties.metadata?._functionCounter;
          const newCount = (count ?? 0) + 1;

          const updatedPageFunction: Omit<PageFunction, 'dataSourceIds'> = {
            ...payload,
            id: newFunctionId,
            name: `Function_${newCount}`,
            createdTime: Number(new Date()),
          };

          const pageFunctionDependencies = buildDependencyGraph({
            [newFunctionId]: updatedPageFunction,
          });

          const dataSourceIds = getUsedDataSourceIds(updatedPageFunction);

          if (!currentPage.properties.pageFunctions) {
            currentPage.properties.pageFunctions = {
              [newFunctionId]: {
                ...updatedPageFunction,
                dataSourceIds,
              },
            };
          } else {
            currentPage.properties.pageFunctions[newFunctionId] = {
              ...updatedPageFunction,
              dataSourceIds,
            };
          }

          setPageFunctionState(newFunctionId, updatedPageFunction as PageFunctionState);

          setDependencies(newFunctionId, pageFunctionDependencies[newFunctionId] ?? []);

          draftState.touchedJavascriptEntities.add(newFunctionId);
        });

        set(newState);
        incrementFunctionCounter();

        return newFunctionId;
      }
      case 'DELETE': {
        const { pageFunctionId } = payload;

        const newState = produce(state, (draftState) => {
          const currentPage = draftState.interfacePages[activePageId];
          invariant(currentPage, `Page with id ${activePageId} not found`);

          if (currentPage.properties.pageFunctions?.[pageFunctionId]) {
            // eslint-disable-next-line @typescript-eslint/no-dynamic-delete -- dynamic delete is required here
            delete currentPage.properties.pageFunctions[pageFunctionId];
          }

          setPageFunctionState(pageFunctionId, undefined);
          draftState.touchedJavascriptEntities.add(pageFunctionId);
        });

        set(newState);
        setDependencies(pageFunctionId, []);

        break;
      }
      case 'UPDATE': {
        const { pageFunctionId, updatedPageFunction } = payload;

        const dataSourceIds = getUsedDataSourceIds(updatedPageFunction);

        const newState = produce(state, (draftState) => {
          const currentPage = draftState.interfacePages[activePageId];
          invariant(currentPage, `Page with id ${activePageId} not found`);

          const finalUpdatedPageFunction: PageFunction = {
            ...updatedPageFunction,
            dataSourceIds,
          };

          const pageFunction = currentPage.properties.pageFunctions?.[pageFunctionId] as
            | PageFunction
            | undefined;
          invariant(pageFunction, `Page function with id ${pageFunctionId} not found`);

          if (currentPage.properties.pageFunctions?.[pageFunctionId]) {
            currentPage.properties.pageFunctions[pageFunctionId] = finalUpdatedPageFunction;
          }

          setPageFunctionState(pageFunctionId, finalUpdatedPageFunction);
          draftState.touchedJavascriptEntities.add(pageFunctionId);
        });

        set(newState);

        const pageFunctionDependencies = buildDependencyGraph({
          [pageFunctionId]: updatedPageFunction,
        });

        setDependencies(pageFunctionId, pageFunctionDependencies[pageFunctionId] ?? []);

        break;
      }
    }
  };

export default getPageFunctionActions;
