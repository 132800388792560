import type { InterfaceEntity } from '@unifyapps/defs/types/interface';
import type { InterfaceDependencyType, InterfacePageEntity } from '@unifyapps/defs/types/page';
import type { StoreApi } from 'zustand';
import type { BlockId, BlockStateUnionType, BlockType } from '@unifyapps/defs/types/block';
import type { PageVariableState } from '@unifyapps/defs/types/pageVariable';
import type { Draft } from 'immer';
import type { PageFunctionState } from '@unifyapps/defs/types/pageFunction';
import type BlocksRegistry from '../../components/RegistryProvider/BlocksRegistry';
import type {
  EntityDependency,
  DeviceDetailsType,
  GlobalStateStoreType,
} from '../GlobalStateStore';
import type { DeviceVariantActionProps } from '../GlobalStateStore/actions/deviceVariantAction';
import type { DataSourceRecordStoreState } from '../DataSourceRecordStore';
import type { UpdateBlockForActiveDeviceActionProps } from './actions/updateBlock/getUpdateBlockForActiveDeviceAction';
import type { CreateBlockActionProps, CreateBlockActionReturnType } from './actions/createBlock';
import type {
  CreateCompositeBlockActionProps,
  CreateCompositeBlockActionReturnType,
} from './actions/createCompositeBlock';
import type { InsertBlockActionProps, InsertBlockActionReturnType } from './actions/insertBlock';
import type { RemoveBlockActionProps, RemoveBlockActionReturnType } from './actions/removeBlock';
import type { DeleteBlockActionProps, DeleteBlockActionReturnType } from './actions/deleteBlock';
import type { MoveBlockActionProps, MoveBlockActionReturnType } from './actions/moveBlock';
import type { ReorderBlockActionProps, ReorderBlockActionReturnType } from './actions/reorderBlock';
import type { RemoveSlotActionProps } from './actions/removeSlotInBlock';
import type {
  PropertyPanelHistoryActionProps,
  NestedPropertyData,
} from './actions/propertyPanelHistoryActions';
import type { BlockInteractionActionProps } from './actions/blockInteractionActions';
import type { BlockLayoutActionProps } from './actions/blockLayoutActions';
import type { PageVariableActionProps } from './actions/getPageVariableActions';
import type { PageFunctionActionProps } from './actions/getPageFunctionActions';
import type { BlockPropertyCounterProps } from './actions/blockPropertyCounterAction';
import type { SetPageHasUnsavedChangesProps } from './actions/setPageHasUnsavedChanges';
import type { UpdateInterfacePageActionProps } from './actions/updateInterfacePageAction';
import type { UpdateInterfaceRecordActionProps } from './actions/updateInterfaceRecordAction';
import type { OnDeviceSwitchActionProps } from './actions/deviceSwitchAction';
import type { UpdateBlockForAllDevicesActionProps } from './actions/updateBlock/getUpdateBlockForAllDevicesAction';
import type { MarkInterfaceRecordDirtyProps } from './actions/getMarkInterfaceRecordDirty';

export type InterfaceStoreRefType = {
  updateBlocks: ({
    added,
    deleted,
    changed,
  }: {
    added: BlockType[];
    deleted: BlockType[];
    changed: BlockType[];
  }) => void;
};

export enum InterfaceModes {
  BUILDER = 'BUILDER',
  PREVIEW = 'PREVIEW',
  RUNNER = 'RUNNER',
}

export enum InterfaceClient {
  // eslint-disable-next-line @typescript-eslint/naming-convention -- UA is short for UnifyApps
  UA_PLATFORM = 'UA_PLATFORM',
  // eslint-disable-next-line @typescript-eslint/naming-convention -- UA is short for UnifyApps
  UA_MATRIX = 'UA_MATRIX',
}

export type InterfaceStoreStateInitialValue = Pick<
  InterfaceStoreState,
  | 'interfacePages'
  | 'interfaceRecord'
  | 'activeInterfacePageId'
  | 'client'
  | 'basePath'
  | 'mode'
  | 'isInteractiveModeEnabled'
  | 'activeBlockId'
  | 'interfacePageEl'
  | 'isPublic'
>;

export type InterfaceStoreState = {
  // Whether the interface is in builder or runner mode
  mode: InterfaceModes;
  /**
   * The mode in which the interface is embedded, used to decide which endpoint to fetch interface record with
   * UA_PLATFORM: Fetch interface record from the entityById endpoint
   * UA_MATRIX: Fetch interface record using the domain of the app
   */
  client: InterfaceClient;
  /**
   * base prefix path is used in navigate to page action to determine a prefix path to be added to the page path
   */
  basePath: string | null;

  interfacePageEl: HTMLDivElement | null;

  // Runner & Preview states
  interfaceRecord: InterfaceEntity;
  interfacePages: Record<string, InterfacePageEntity>;

  isInterfaceRecordDirty: boolean;

  //showPageSettings
  pageSettingDetails: {
    show: boolean;
    interfacePageId: string;
    source: 'root' | 'settings';
  } | null;

  /**
   * If the page is public, then the side nav should not be rendered, and navigation should handle that
   */
  isPublic: boolean;

  // Builder states
  activeInterfacePageId: string;
  activeBlockId: BlockId;
  hoverBlockId: BlockId;
  pathToAddComponentPlaceholder: string | null;
  copiedBlockDetails: {
    blockId: BlockId;
    source: 'menu' | 'canvas' | 'panel';
    type: 'copyStyles' | 'copyBlock';
  } | null;

  /**
   *  this variable is used to enable processing of events in builder to Event Handler
   */
  isInteractiveModeEnabled: boolean;

  // touched block set
  touchedBlocks: Set<BlockId>;
  touchedJavascriptEntities: Set<string>;
  dirtyPages: Set<string>;

  propertyPanel: {
    history: {
      blockId: BlockId;
      viewDetails:
        | {
            viewType: 'FIELD_DETAILS';
            data: { id: string; headerLabel?: string };
          }
        | {
            viewType: 'FORM_DETAILS';
            data: { path: string[] };
          }
        | {
            viewType: 'BLOCK_DETAILS' | 'TABLE_PAGE_DETAILS';
            data: undefined;
          }
        | {
            viewType: 'TAB_DETAILS';
            data: { tabId: string };
          }
        | {
            viewType: 'SLIDE_DETAILS';
            data: { path: string };
          }
        | {
            viewType: 'STEP_DETAILS';
            data: { path: string };
          }
        | { viewType: 'CHART_SERIES_FIELD_DETAILS'; data: { id: string } }
        | { viewType: 'STAT_CARD_DETAILS'; data: { id: string } }
        | {
            viewType:
              | 'ROW_ACTION_DETAILS'
              | 'TOOLBAR_CUSTOM_ACTION_DETAILS'
              | 'BULK_ACTION_DETAILS';
            data: { id: string; interfacePages: InterfacePageEntity[]; headerLabel?: string };
          }
        | {
            viewType:
              | 'CHART_SUMMARY_FIELD_DETAILS'
              | 'TABLE_TOOLBAR_DETAILS'
              | 'TABLE_SAVE_ACTION_DETAILS'
              | 'TABLE_TOOLBAR_FILTER_DETAILS'
              | 'TABLE_TOOLBAR_SORT_DETAILS'
              | 'COLUMN_DETAILS'
              | 'MAPPED_COLUMN_DETAILS'
              | 'MAPPED_FORM_FIELD_DETAILS'
              | 'NESTED_TABLE_COLUMN'
              | 'NAVIGATION_MENU_ITEM'
              | 'FILTER_DETAILS'
              | 'SORT_FILTER_DETAILS'
              | 'AI_FILTER_DETAILS'
              | 'DATE_FILTER_DETAILS'
              | 'QUICK_FILTER_DETAILS'
              | 'SEARCH_FILTER_DETAILS'
              | 'FILE_UPLOAD_TEMPLATE_DETAILS'
              | 'RICH_TEXT_TOOLBAR_DETAILS'
              | 'TEXT_INPUT_LABEL_DETAILS'
              | 'ICON_DETAILS'
              | 'TIMELINE_AUDIT'
              | 'BUTTON_DETAILS'
              | 'COMMENT_ACTION_DETAILS'
              | 'CALENDER_VALIDATION'
              | 'TYPOGRAPHY_TRUNCATION'
              | 'RECAPTCHA_VALIDATION'
              | 'SLIDE_DETAILS'
              | 'TAB_DETAILS'
              | 'STEP_DETAILS'
              | 'MENU_ITEM_DETAILS'
              | 'KEY_VALUE_FIELD_DECORATOR_DETAILS'
              | 'KEY_VALUE_VALUE_DECORATOR_DETAILS'
              | 'AUDIO_WAVEFORM_MARKER_DETAILS'
              | 'DATETIME_DETAILS'
              | 'GROUPED_COLUMN_DETAILS'
              | 'COLUMN_ACTION_DETAILS'
              | 'PDF_VIEWER_TOOLBAR_DETAILS';
            data: NestedPropertyData;
          };
    }[];
  };
};

export type UpdateBlockStateType<P = unknown> = (
  id: string,
  setter: (blockState: Draft<P>) => void,
) => void;

export type InterfaceStoreStateGetterAndSetter = {
  set: StoreApi<InterfaceStoreType>['setState'];
  get: StoreApi<InterfaceStoreType>['getState'];
  registry: BlocksRegistry;
  setBlockState: (id: string, blockState: BlockStateUnionType | undefined) => void;
  onChangeActiveBlockId?: (blockId: BlockId) => void;
  updateBlockState: UpdateBlockStateType;
  setBlocksState: (blocks: Record<string, BlockStateUnionType | undefined>) => void;
  setPageVariableState: (id: string, pageVariableState: PageVariableState) => void;
  setPageFunctionState: (id: string, pageFunctionState: PageFunctionState) => void;
  dataSourceExists: (dataSourceId: string) => boolean;
  handleDeviceVariantChange: (args: DeviceVariantActionProps) => void;
  resetBlockRefs: GlobalStateStoreType['actions']['resetBlockRefs'];
  getDeviceDetails: () => DeviceDetailsType;
  getDataSourceRecordsStore: StoreApi<DataSourceRecordStoreState>['getState'];
  setDependencies: (id: string, dependencies: EntityDependency[]) => void;
};

export type InterfaceStoreType = InterfaceStoreState & {
  actions: {
    incrementBlockCounter: (componentType: string) => number;
    incrementVariableCounter: () => number;
    incrementFunctionCounter: () => number;
    incrementPageEventCounter: () => number;
    setActiveBlockId: (activeBlockId: BlockId) => void;
    setPageSettingDetails: (
      pageSettingsParams: {
        show: boolean;
        interfacePageId: string;
        source: 'root' | 'settings';
      } | null,
    ) => void;
    setHoverBlockId: (hoverBlockId: BlockId) => void;
    setPathToAddComponentPlaceholder: (path: string | null) => void;
    /** creates a new block and initializes its state from given block type */
    createBlock: (args: CreateBlockActionProps) => CreateBlockActionReturnType;
    createCompositeBlock: (
      args: CreateCompositeBlockActionProps,
    ) => CreateCompositeBlockActionReturnType;
    /** inserts a pre existing block into the hierarchy */
    insertBlock: (args: InsertBlockActionProps) => InsertBlockActionReturnType;
    /** update block namespace consists of actions updating blocks in interfacepage */
    updateBlock: {
      /** Use this action when you are chainging in block and that change can be a device specifc change
       * i.e changing in block that is only for a specific device
       * This also creates a new block state for the block from the active device
       */
      forActiveDevice: (args: UpdateBlockForActiveDeviceActionProps) => void;
      /** Use this action when you are chainging in block and that change should be reflected in all devices
       * i.e changing hierarchy related changes in block
       */
      forAllDevices: (args: UpdateBlockForAllDevicesActionProps) => void;
    };
    /** removes the given block from the hierarchy. does not delete its children or its state */
    removeBlock: (args: RemoveBlockActionProps) => RemoveBlockActionReturnType;
    /** deletes the block and its state from the page store. does not delete its children */
    deleteBlock: (args: DeleteBlockActionProps) => DeleteBlockActionReturnType;
    /** moves the block to and empty block */
    moveBlock: (args: MoveBlockActionProps) => MoveBlockActionReturnType;
    /** reorders the block in the blockIds field */
    reorderBlock: (args: ReorderBlockActionProps) => ReorderBlockActionReturnType;
    /** only deletes the given slot from the block. does not delete its children */
    removeSlotInBlock: (args: RemoveSlotActionProps) => void;
    setMode: (mode: InterfaceStoreState['mode']) => void;
    propertyPanelHistoryActions: (args: PropertyPanelHistoryActionProps) => void;
    blockInteractionActions: (args: BlockInteractionActionProps) => void;
    blockLayoutActions: (args: BlockLayoutActionProps) => void;
    pageVariableActions: (args: PageVariableActionProps) => string | undefined;
    pageFunctionActions: (args: PageFunctionActionProps) => string | undefined;
    blockPropertyCounterAction: (args: BlockPropertyCounterProps) => number;
    setPageHasUnsavedChanges: (args: SetPageHasUnsavedChangesProps) => void;
    updateInterfacePageAction: (args: UpdateInterfacePageActionProps) => void;
    updateInterfaceRecordAction: (args: UpdateInterfaceRecordActionProps) => void;
    insertDependencies: (dependencies: [InterfaceDependencyType, InterfaceDependencyType]) => void;
    onDeviceSwitch: (args: OnDeviceSwitchActionProps) => void;
    clearTouchedBlocks: () => void;
    setCopiedBlockDetails: (copiedBlock: InterfaceStoreState['copiedBlockDetails']) => void;
    clearTouchedJavascriptEntities: () => void;
    toggleInteractiveMode: () => void;
    setInterfacePageEl: (ref: HTMLDivElement | null) => void;
    markInterfaceRecordDirty: (args: MarkInterfaceRecordDirtyProps) => void;
  };
};

export type AdditionalArgs = Omit<InterfaceStoreStateGetterAndSetter, 'set' | 'get'>;
