import omit from 'lodash/omit';
import { produce } from 'immer';
import type { WritableDraft } from 'immer';
import type { BlockType } from '@unifyapps/defs/types/block';
import type {
  DataTableComponentType,
  NestedTableComponentType,
} from '@unifyapps/defs/blocks/Table/NestedTable/types';

type TableColumn = DataTableComponentType['content']['columns'][number];

const getPartialColumn = (column: TableColumn) => {
  if (
    column.type === 'TAG' &&
    column.editCellComponentProps?.optionsType === 'LOOKUP' &&
    typeof column.editCellComponentProps.foreignKeyReference === 'object'
  ) {
    return omit(column, ['editCellComponentProps.foreignKeyReference']) as typeof column;
  }
  return column;
};

export const getPartialBlockToExtractEntityIds = (block: Omit<BlockType, 'events'>) => {
  switch (block.component.componentType) {
    case 'NestedTable': {
      return produce(block, (draft: WritableDraft<BlockType<NestedTableComponentType>>) => {
        draft.component.content.columns = draft.component.content.columns.reduce<TableColumn[]>(
          (accumulator, current) => {
            if (current.type !== 'NESTED_TABLE') {
              accumulator.push(getPartialColumn(current));
            }
            return accumulator;
          },
          [],
        );
      });
    }
    case 'Table': {
      return produce(block, (draft: WritableDraft<BlockType<DataTableComponentType>>) => {
        draft.component.content.columns = draft.component.content.columns.map(getPartialColumn);
      });
    }
    default:
      return block;
  }
};
