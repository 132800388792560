import type { InterfaceStoreStateGetterAndSetter } from '../types';

const getClearTouchedJavascriptEntities =
  ({ get, set }: InterfaceStoreStateGetterAndSetter) =>
  () => {
    set({
      touchedJavascriptEntities: new Set(),
    });
  };

export default getClearTouchedJavascriptEntities;
