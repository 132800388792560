import { useLookup } from '@unifyapps/network/generated/lookup-rest-api/lookup-rest-api';
import type { ErrorType } from '@unifyapps/network/utils/fetch';
import { DUMMY_INTERFACE_RECORD } from '../../const';
import { InterfaceClient, InterfaceModes } from '../../stores/InterfaceStore/types';
import { interfacePermissionsLookupByQueryRequest } from '../permissions';
import type { UserPermissions, UserPermissionsMap } from '../types';
import { adaptCurrentUserPermissions } from '../utils/adaptUserPermissions';
import userStore from '../../../auth/userStore';

type Args = {
  mode?: InterfaceModes;
  interfaceId: string | undefined;
  client: InterfaceClient;
};

type Return = {
  permissions: UserPermissionsMap;
  isLoading: boolean;
  error: ErrorType<unknown> | null;
};
export const useFetchInterfacePermissions = ({ mode, interfaceId, client }: Args): Return => {
  const userContext = userStore.use.currentUserDetails();

  // userContext.external will be true if calls are made via /api-endpoint/external
  // this endpoint is used in public integrations (delta-matrix) and the user context is not available, these are usually public interfaces
  // eg. shopify integration
  // @ts-expect-error -- backend is not using userContext bean for external users they are simply returning {external: true}, that's why it's not defined in the types
  const isExternalUser = userContext?.external as boolean | undefined;
  const { data, isLoading, error } = useLookup<{
    response?: {
      objects?: UserPermissions;
    };
  }>(interfacePermissionsLookupByQueryRequest, {
    query: {
      enabled: Boolean(
        !process.env.DELTA_MATRIX &&
          mode === InterfaceModes.RUNNER &&
          userContext && // call shouldn't go on login page, permissions are only required for logged in users
          !isExternalUser &&
          // We use DUMMY_INTERFACE_RECORD in PlatformInterfaceResourceProvider for platform interfaces, and we dont want to fetch permissions for it
          interfaceId !== DUMMY_INTERFACE_RECORD.id &&
          client !== InterfaceClient.UA_PLATFORM,
      ),
      staleTime: Infinity,
    },
  });

  const adaptedPermissions = adaptCurrentUserPermissions(data?.response?.objects ?? []);

  return { permissions: adaptedPermissions, isLoading, error };
};
