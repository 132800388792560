import { invariant } from 'ts-invariant';
import { createDraft } from 'immer';
import type { InterfaceStoreStateGetterAndSetter } from '../types';

export function getDraftInterfacePage(get: InterfaceStoreStateGetterAndSetter['get']) {
  const activePageId = get().activeInterfacePageId;
  const currentPage = get().interfacePages[activePageId];
  invariant(currentPage, `getRemoveBlockAction: Page with id ${activePageId} not found`);
  return createDraft(currentPage);
}
