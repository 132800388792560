import _set from 'lodash/set';
import { produce } from 'immer';
import type { InterfacePageEntity } from '@unifyapps/defs/types/page';
import type { InterfaceStoreStateGetterAndSetter } from '../types';

export type UpdateInterfacePageActionProps = {
  interfacePage: InterfacePageEntity;
};

const getUpdateInterfacePageAction =
  (storeArgs: InterfaceStoreStateGetterAndSetter) => (args: UpdateInterfacePageActionProps) => {
    const { get, set } = storeArgs;
    const { interfacePage } = args;
    set({
      interfacePages: produce(get().interfacePages, (draftPages) => {
        _set(draftPages, [interfacePage.id], interfacePage);
      }),
    });
  };

export default getUpdateInterfacePageAction;
