import _set from 'lodash/set';
import type { InterfaceStoreStateGetterAndSetter } from '../types';

export type MarkInterfaceRecordDirtyProps = {
  isInterfaceRecordDirty?: boolean;
};

const getMarkInterfaceRecordDirty =
  (storeArgs: InterfaceStoreStateGetterAndSetter) => (args: MarkInterfaceRecordDirtyProps) => {
    const { set } = storeArgs;
    const { isInterfaceRecordDirty } = args;
    set({
      isInterfaceRecordDirty,
    });
  };

export default getMarkInterfaceRecordDirty;
