import _set from 'lodash/set';
import type { InterfaceEntity } from '@unifyapps/defs/types/interface';
import type { InterfaceStoreStateGetterAndSetter } from '../types';

export type UpdateInterfaceRecordActionProps = {
  interfaceRecord: InterfaceEntity;
  /**
   * Determines whether to mark the interface record as dirty.
   * If set to true:
   * - The interface record will not be marked as dirty used when updating the interface record after save.
   *
   * If false or omitted:
   * - The interface record will be marked as dirty.
   */
  isInterfaceRecordClean?: boolean;
};

const getUpdateInterfaceRecordAction =
  (storeArgs: InterfaceStoreStateGetterAndSetter) => (args: UpdateInterfaceRecordActionProps) => {
    const { set } = storeArgs;
    const { interfaceRecord, isInterfaceRecordClean } = args;
    set({
      interfaceRecord,
      isInterfaceRecordDirty: !isInterfaceRecordClean,
    });
  };

export default getUpdateInterfaceRecordAction;
