import type { BlockType } from '@unifyapps/defs/types/block';

// eslint-disable-next-line @typescript-eslint/no-extraneous-class -- required
class CompositeBlockHelper {
  /**
   * Generates the display name for a block based on the persistDisplayName flag.
   *
   * If persistDisplayName is true, the block's current displayName is preserved
   * if it exists. Otherwise, the displayName is generated using the componentType
   * and a new count.
   */
  static generateDisplayName({
    componentType,
    newCount,
    persistDisplayName,
    block,
  }: {
    componentType: string;
    newCount: number;
    persistDisplayName: boolean;
    block: Partial<BlockType>;
  }): string {
    return persistDisplayName
      ? (block.displayName ?? `${componentType}_${newCount}`)
      : `${componentType}_${newCount}`;
  }
}

export { CompositeBlockHelper };
