import type { StoreApi } from 'zustand/vanilla';
import type { ContextType, PropsWithChildren } from 'react';
import { createContext, useContext, useRef } from 'react';
import type { WithSelectors } from '../../../utils/state/zustand';
import { createSelectors } from '../../../utils/state/zustand';
import { createDataSourceRecordStore } from './createDataSourceRecordStore';
import type { DataSourceRecordStoreState, DataSourceRecordStoreType } from './types';

export const DataSourceRecordStoreContext = createContext<WithSelectors<
  StoreApi<DataSourceRecordStoreType>
> | null>(null);

export const useDataSourceRecordStore = () => {
  const _useStore = useContext(DataSourceRecordStoreContext);
  if (!_useStore) {
    throw new Error('Missing DataSourceRecordStoreProvider');
  }
  return _useStore;
};

export function DataSourceRecordStoreProvider({
  initialValue,
  children,
}: PropsWithChildren<{
  initialValue: Partial<DataSourceRecordStoreState>;
}>) {
  const storeRef = useRef<ContextType<typeof DataSourceRecordStoreContext>>();

  if (!storeRef.current) {
    storeRef.current = createSelectors(createDataSourceRecordStore({ ...initialValue }));
  }

  return (
    <DataSourceRecordStoreContext.Provider value={storeRef.current}>
      {children}
    </DataSourceRecordStoreContext.Provider>
  );
}
