import type { StoreApi } from 'zustand';
import { createDraft, finishDraft, type Draft } from 'immer';

function createObjectSetters<
  T extends object,
  K extends keyof T,
  V extends T[K] extends Record<string, infer U> ? U : never,
>(key: K, set: StoreApi<T>['setState']) {
  const setSingle = (id: string, value: V) =>
    set((state) => ({
      ...state,
      [key]: { ...state[key], [id]: value },
    }));

  const setMultiple = (newValues: Partial<T[K]>) =>
    set((state) => ({
      ...state,
      [key]: { ...state[key], ...newValues },
    }));

  const remove = (id: string) =>
    set((state) => ({
      ...state,
      [key]: Object.fromEntries(
        Object.entries(state[key] as Record<string, V>).filter(([k]) => k !== id),
      ) as T[K],
    }));

  const removeMultiple = (ids: string[]) =>
    set((state) => ({
      ...state,
      [key]: Object.fromEntries(
        Object.entries(state[key] as Record<string, V>).filter(([k]) => !ids.includes(k)),
      ) as T[K],
    }));

  const updateSingle = <PayloadType>(
    id: string,
    mutator: (draft: Draft<PayloadType | undefined>) => void,
  ) =>
    set((state) => {
      const currentValue = state[key] as Record<string, V>;
      const currentIdValue = createDraft(currentValue[id] ?? {}) as Draft<V>;
      mutator(currentIdValue as unknown as Draft<PayloadType>);
      const value = finishDraft(currentIdValue);

      return {
        ...state,
        [key]: {
          ...currentValue,
          [id]: value,
        },
      };
    });

  return { setSingle, setMultiple, remove, removeMultiple, updateSingle };
}

export default createObjectSetters;
