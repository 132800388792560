/**
 * Generated by orval 🍺
 * Do not edit manually.
 * api API
 * OpenAPI spec version: 1.0
 */
import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import type {
  InfiniteData,
  QueryClient,
  QueryFunction,
  QueryKey,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import type { BulkLookup200 } from '../models/bulkLookup200';
import type { BulkLookupRequest } from '../models/bulkLookupRequest';
import type { LookupByKeysRequest } from '../models/lookupByKeysRequest';
import type { LookupRequest } from '../models/lookupRequest';
import type { LookupResponse } from '../models/lookupResponse';
import type { SourceEnvLookupParams } from '../models/sourceEnvLookupParams';
import { executeFetch } from '../../utils/fetch/fetch';
import type { ErrorType } from '../../utils/fetch/fetch';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

export const lookup = (
  lookupRequest: LookupRequest,
  options?: SecondParameter<typeof executeFetch>,
) => {
  return executeFetch<LookupResponse>(
    {
      url: `/api/lookup`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: lookupRequest,
    },
    options,
  );
};

export const getLookupQueryKey = (lookupRequest: LookupRequest) => {
  return [`/api/lookup`, lookupRequest] as const;
};

export const getLookupInfiniteQueryOptions = <
  TData = InfiniteData<Awaited<ReturnType<typeof lookup>>>,
  TError = ErrorType<unknown>,
>(
  lookupRequest: LookupRequest,
  options?: {
    query?: Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof lookup>>, TError, TData>>;
    request?: SecondParameter<typeof executeFetch>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getLookupQueryKey(lookupRequest);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof lookup>>> = () =>
    lookup(lookupRequest, requestOptions);

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof lookup>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type LookupInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof lookup>>>;
export type LookupInfiniteQueryError = ErrorType<unknown>;

export const useLookupInfinite = <
  TData = InfiniteData<Awaited<ReturnType<typeof lookup>>>,
  TError = ErrorType<unknown>,
>(
  lookupRequest: LookupRequest,
  options?: {
    query?: Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof lookup>>, TError, TData>>;
    request?: SecondParameter<typeof executeFetch>;
  },
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getLookupInfiniteQueryOptions(lookupRequest, options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const prefetchLookupInfinite = async <
  TData = Awaited<ReturnType<typeof lookup>>,
  TError = ErrorType<unknown>,
>(
  queryClient: QueryClient,
  lookupRequest: LookupRequest,
  options?: {
    query?: Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof lookup>>, TError, TData>>;
    request?: SecondParameter<typeof executeFetch>;
  },
): Promise<QueryClient> => {
  const queryOptions = getLookupInfiniteQueryOptions(lookupRequest, options);

  await queryClient.prefetchInfiniteQuery(queryOptions);

  return queryClient;
};

export const getLookupQueryOptions = <
  TData = Awaited<ReturnType<typeof lookup>>,
  TError = ErrorType<unknown>,
>(
  lookupRequest: LookupRequest,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof lookup>>, TError, TData>>;
    request?: SecondParameter<typeof executeFetch>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getLookupQueryKey(lookupRequest);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof lookup>>> = () =>
    lookup(lookupRequest, requestOptions);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof lookup>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type LookupQueryResult = NonNullable<Awaited<ReturnType<typeof lookup>>>;
export type LookupQueryError = ErrorType<unknown>;

export const useLookup = <TData = Awaited<ReturnType<typeof lookup>>, TError = ErrorType<unknown>>(
  lookupRequest: LookupRequest,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof lookup>>, TError, TData>>;
    request?: SecondParameter<typeof executeFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getLookupQueryOptions(lookupRequest, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const prefetchLookup = async <
  TData = Awaited<ReturnType<typeof lookup>>,
  TError = ErrorType<unknown>,
>(
  queryClient: QueryClient,
  lookupRequest: LookupRequest,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof lookup>>, TError, TData>>;
    request?: SecondParameter<typeof executeFetch>;
  },
): Promise<QueryClient> => {
  const queryOptions = getLookupQueryOptions(lookupRequest, options);

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const bulkLookup = (
  bulkLookupRequest: BulkLookupRequest,
  options?: SecondParameter<typeof executeFetch>,
) => {
  return executeFetch<BulkLookup200>(
    {
      url: `/api/lookup/bulk`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: bulkLookupRequest,
    },
    options,
  );
};

export const getBulkLookupQueryKey = (bulkLookupRequest: BulkLookupRequest) => {
  return [`/api/lookup/bulk`, bulkLookupRequest] as const;
};

export const getBulkLookupQueryOptions = <
  TData = Awaited<ReturnType<typeof bulkLookup>>,
  TError = ErrorType<unknown>,
>(
  bulkLookupRequest: BulkLookupRequest,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof bulkLookup>>, TError, TData>>;
    request?: SecondParameter<typeof executeFetch>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getBulkLookupQueryKey(bulkLookupRequest);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof bulkLookup>>> = () =>
    bulkLookup(bulkLookupRequest, requestOptions);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof bulkLookup>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type BulkLookupQueryResult = NonNullable<Awaited<ReturnType<typeof bulkLookup>>>;
export type BulkLookupQueryError = ErrorType<unknown>;

export const useBulkLookup = <
  TData = Awaited<ReturnType<typeof bulkLookup>>,
  TError = ErrorType<unknown>,
>(
  bulkLookupRequest: BulkLookupRequest,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof bulkLookup>>, TError, TData>>;
    request?: SecondParameter<typeof executeFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getBulkLookupQueryOptions(bulkLookupRequest, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const prefetchBulkLookup = async <
  TData = Awaited<ReturnType<typeof bulkLookup>>,
  TError = ErrorType<unknown>,
>(
  queryClient: QueryClient,
  bulkLookupRequest: BulkLookupRequest,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof bulkLookup>>, TError, TData>>;
    request?: SecondParameter<typeof executeFetch>;
  },
): Promise<QueryClient> => {
  const queryOptions = getBulkLookupQueryOptions(bulkLookupRequest, options);

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const lookupSingleKey = (
  lookupByKeysRequest: LookupByKeysRequest,
  options?: SecondParameter<typeof executeFetch>,
) => {
  return executeFetch<unknown>(
    {
      url: `/api/lookup/single-key`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: lookupByKeysRequest,
    },
    options,
  );
};

export const getLookupSingleKeyQueryKey = (lookupByKeysRequest: LookupByKeysRequest) => {
  return [`/api/lookup/single-key`, lookupByKeysRequest] as const;
};

export const getLookupSingleKeyQueryOptions = <
  TData = Awaited<ReturnType<typeof lookupSingleKey>>,
  TError = ErrorType<unknown>,
>(
  lookupByKeysRequest: LookupByKeysRequest,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof lookupSingleKey>>, TError, TData>>;
    request?: SecondParameter<typeof executeFetch>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getLookupSingleKeyQueryKey(lookupByKeysRequest);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof lookupSingleKey>>> = () =>
    lookupSingleKey(lookupByKeysRequest, requestOptions);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof lookupSingleKey>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type LookupSingleKeyQueryResult = NonNullable<Awaited<ReturnType<typeof lookupSingleKey>>>;
export type LookupSingleKeyQueryError = ErrorType<unknown>;

export const useLookupSingleKey = <
  TData = Awaited<ReturnType<typeof lookupSingleKey>>,
  TError = ErrorType<unknown>,
>(
  lookupByKeysRequest: LookupByKeysRequest,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof lookupSingleKey>>, TError, TData>>;
    request?: SecondParameter<typeof executeFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getLookupSingleKeyQueryOptions(lookupByKeysRequest, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const prefetchLookupSingleKey = async <
  TData = Awaited<ReturnType<typeof lookupSingleKey>>,
  TError = ErrorType<unknown>,
>(
  queryClient: QueryClient,
  lookupByKeysRequest: LookupByKeysRequest,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof lookupSingleKey>>, TError, TData>>;
    request?: SecondParameter<typeof executeFetch>;
  },
): Promise<QueryClient> => {
  const queryOptions = getLookupSingleKeyQueryOptions(lookupByKeysRequest, options);

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const sourceEnvLookup = (
  lookupRequest: LookupRequest,
  params?: SourceEnvLookupParams,
  options?: SecondParameter<typeof executeFetch>,
) => {
  return executeFetch<LookupResponse>(
    {
      url: `/api/lookup/source-env`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: lookupRequest,
      params,
    },
    options,
  );
};

export const getSourceEnvLookupQueryKey = (
  lookupRequest: LookupRequest,
  params?: SourceEnvLookupParams,
) => {
  return [`/api/lookup/source-env`, ...(params ? [params] : []), lookupRequest] as const;
};

export const getSourceEnvLookupInfiniteQueryOptions = <
  TData = InfiniteData<Awaited<ReturnType<typeof sourceEnvLookup>>>,
  TError = ErrorType<unknown>,
>(
  lookupRequest: LookupRequest,
  params?: SourceEnvLookupParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<Awaited<ReturnType<typeof sourceEnvLookup>>, TError, TData>
    >;
    request?: SecondParameter<typeof executeFetch>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getSourceEnvLookupQueryKey(lookupRequest, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof sourceEnvLookup>>> = () =>
    sourceEnvLookup(lookupRequest, params, requestOptions);

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof sourceEnvLookup>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type SourceEnvLookupInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof sourceEnvLookup>>
>;
export type SourceEnvLookupInfiniteQueryError = ErrorType<unknown>;

export const useSourceEnvLookupInfinite = <
  TData = InfiniteData<Awaited<ReturnType<typeof sourceEnvLookup>>>,
  TError = ErrorType<unknown>,
>(
  lookupRequest: LookupRequest,
  params?: SourceEnvLookupParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<Awaited<ReturnType<typeof sourceEnvLookup>>, TError, TData>
    >;
    request?: SecondParameter<typeof executeFetch>;
  },
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getSourceEnvLookupInfiniteQueryOptions(lookupRequest, params, options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const prefetchSourceEnvLookupInfinite = async <
  TData = Awaited<ReturnType<typeof sourceEnvLookup>>,
  TError = ErrorType<unknown>,
>(
  queryClient: QueryClient,
  lookupRequest: LookupRequest,
  params?: SourceEnvLookupParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<Awaited<ReturnType<typeof sourceEnvLookup>>, TError, TData>
    >;
    request?: SecondParameter<typeof executeFetch>;
  },
): Promise<QueryClient> => {
  const queryOptions = getSourceEnvLookupInfiniteQueryOptions(lookupRequest, params, options);

  await queryClient.prefetchInfiniteQuery(queryOptions);

  return queryClient;
};

export const getSourceEnvLookupQueryOptions = <
  TData = Awaited<ReturnType<typeof sourceEnvLookup>>,
  TError = ErrorType<unknown>,
>(
  lookupRequest: LookupRequest,
  params?: SourceEnvLookupParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof sourceEnvLookup>>, TError, TData>>;
    request?: SecondParameter<typeof executeFetch>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getSourceEnvLookupQueryKey(lookupRequest, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof sourceEnvLookup>>> = () =>
    sourceEnvLookup(lookupRequest, params, requestOptions);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof sourceEnvLookup>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type SourceEnvLookupQueryResult = NonNullable<Awaited<ReturnType<typeof sourceEnvLookup>>>;
export type SourceEnvLookupQueryError = ErrorType<unknown>;

export const useSourceEnvLookup = <
  TData = Awaited<ReturnType<typeof sourceEnvLookup>>,
  TError = ErrorType<unknown>,
>(
  lookupRequest: LookupRequest,
  params?: SourceEnvLookupParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof sourceEnvLookup>>, TError, TData>>;
    request?: SecondParameter<typeof executeFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getSourceEnvLookupQueryOptions(lookupRequest, params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const prefetchSourceEnvLookup = async <
  TData = Awaited<ReturnType<typeof sourceEnvLookup>>,
  TError = ErrorType<unknown>,
>(
  queryClient: QueryClient,
  lookupRequest: LookupRequest,
  params?: SourceEnvLookupParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof sourceEnvLookup>>, TError, TData>>;
    request?: SecondParameter<typeof executeFetch>;
  },
): Promise<QueryClient> => {
  const queryOptions = getSourceEnvLookupQueryOptions(lookupRequest, params, options);

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};
