import { useState, useCallback } from 'react';
import { produce } from 'immer';

const RETRY = 2;

export const useRemountProps = ({ remountOnError }: { remountOnError?: boolean }) => {
  const [errorState, setErrorState] = useState<{
    errorsMap: Record<string, number | undefined>;
    cappedErrorCount: number;
  }>({ cappedErrorCount: 0, errorsMap: {} });
  const { cappedErrorCount } = errorState;

  const onError = useCallback((error: unknown) => {
    if (error && typeof error === 'object' && 'stack' in error) {
      const errorStack = error.stack as string;

      setErrorState((prev) =>
        produce(prev, (draft) => {
          /**
           * Check whether the error has been logged before
           * If it has occurred lesser than the retry count, increment the capped error count
           * Capped Error count is being used in key and will hence update the key to remount the component
           */
          if (
            !draft.errorsMap[errorStack] ||
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- checked for undefined
            draft.errorsMap[errorStack]! < RETRY
          ) {
            draft.cappedErrorCount = draft.cappedErrorCount + 1;
          }

          /**
           * Update the error count for the error stack
           */
          draft.errorsMap[errorStack] = (draft.errorsMap[errorStack] ?? 0) + 1;
        }),
      );
    }
  }, []);

  return {
    onError: remountOnError ? onError : undefined,
    key: remountOnError ? cappedErrorCount : undefined,
  };
};
