/* eslint-disable tsdoc/syntax -- need to escape otherwise, making it unreadble in file */
/**
 * Regular Expression for matching strings that end with a specific pattern {{ ... }},
 * where the content inside the double curly braces is captured as the "id."
 *
 * Regex Pattern Breakdown:
 * - {{\s*: Literal string {{ followed by zero or more whitespace characters.
 * - (?<id>[^({}\s)]+): Named capturing group "id" capturing one or more non-{{}}, non-whitespace characters.
 * - \s*}}: Zero or more whitespace characters followed by the literal string }}.
 */
export const STRINGIFIED_EXPRESSION = '{{\\s*(?<id>[^{}\\s]+)\\s*}}';
export const EXPRESSION_REGEX = new RegExp(STRINGIFIED_EXPRESSION, 'g');
