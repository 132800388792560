import type { BlockType, BlockId } from '@unifyapps/defs/types/block';
import { produce, enableMapSet } from 'immer';
import _filter from 'lodash/filter';
import { getEntityIdsReferencedInBlock } from '../../../../utils/getEntityIdsReferencedInBlock';
import type { InterfaceStoreStateGetterAndSetter } from '../../types';
import { getSetBlock } from '../../utils/block';
import { updateBlockStateByActiveDevice } from '../../utils/updateBlockStateByActiveDevice';
import { getPartialBlockToExtractEntityIds } from '../../utils/getPartialBlockToExtractEntityIds';

//for immer to work with Map and Set
enableMapSet();

export type UpdateBlockForActiveDeviceActionProps = {
  blockId: BlockId;
  block: BlockType;
};

export const getUpdateBlockForActiveDeviceAction =
  (storeArgs: InterfaceStoreStateGetterAndSetter) =>
  (args: UpdateBlockForActiveDeviceActionProps) => {
    const { get, registry, dataSourceExists, set } = storeArgs;
    const { blockId, block } = args;
    if (!blockId) return;

    const activePageId = get().activeInterfacePageId;
    const touchedBlocks = get().touchedBlocks;

    // Remove events because we don't want to find datasources that are used inside events
    const { events: _, ...blockWithoutEvents } = block;
    const dependentEntityIds = getEntityIdsReferencedInBlock(
      getPartialBlockToExtractEntityIds(blockWithoutEvents),
    );
    const dataSourceIds = dependentEntityIds.filter(dataSourceExists);

    let events = block.events;
    const eventTargetIds = registry.getEventTargetIds(block.component.componentType, block);

    if (events?.length && eventTargetIds) {
      events = events.filter(({ targetId }) => !targetId || eventTargetIds.includes(targetId));
    }

    const updateBlock = {
      ...block,
      events,
      dataSourceIds,
    };

    const setBlock = getSetBlock(storeArgs);

    setBlock({ pageId: activePageId, block: updateBlock, blockId });
    set({
      touchedBlocks: produce(touchedBlocks, (draft) => {
        draft.add(blockId);
      }),
    });

    updateBlockStateByActiveDevice({ storeArgs, blockId });
  };
