import type { InterfaceStoreStateGetterAndSetter } from '../types';

const getSetPathToAddComponentPlaceholderAction =
  ({ set }: InterfaceStoreStateGetterAndSetter) =>
  (path: string | null) => {
    set({
      pathToAddComponentPlaceholder: path,
    });
  };

export default getSetPathToAddComponentPlaceholderAction;
