import type { BlockStateUnionType, BlockType } from '@unifyapps/defs/types/block';
import _omit from 'lodash/omit';
import type { InterfacePageEntity } from '@unifyapps/defs/types/page';
import type { DataSourceEntity } from '@unifyapps/defs/types/dataSource';
import { getIdWithPrefix } from '../../../../utils/id';
import DataSourceHelper from '../../../helper/DataSourceHelper';
import { REQUEST_ID_PREFIX } from '../../../const';
import { DependencyFlowStatus, type GlobalStateStore } from '../types';
import type BlocksRegistry from '../../../components/RegistryProvider/BlocksRegistry';

export function getInitialBlockState({
  blocks,
  registry,
}: {
  blocks: Record<string, BlockType>;
  registry: BlocksRegistry;
}) {
  return Object.keys(blocks).reduce<GlobalStateStore['blocks']>((acc, key) => {
    const componentType = blocks[key].component.componentType;

    const initialState = registry.getBlockInitialState(componentType, blocks[key]);
    acc[key] = (initialState ?? {}) as BlockStateUnionType;
    return acc;
  }, {});
}

export function getInitialPageVariablesState({
  pageVariables,
}: {
  pageVariables: NonNullable<InterfacePageEntity['properties']['pageVariables']>;
}) {
  return Object.keys(pageVariables).reduce<GlobalStateStore['pageVariables']>((acc, key) => {
    acc[key] = {
      ..._omit(pageVariables[key], ['initialValue']),
      value: pageVariables[key].initialValue,
    };
    return acc;
  }, {});
}

/**
 * when the app is initialy loaded, we need to fetch the initial page load data sources, so we push them to staging env
 * where they will also add their dependencies to staging env if they have any
 */
export const getInitialDatasourceDependencyFlow = (
  dataSources?: Record<string, DataSourceEntity | undefined>,
) => {
  const automaticPageLoad = dataSources
    ? DataSourceHelper.getAutomaticPageLoadDataSources(dataSources)
    : [];

  return automaticPageLoad.reduce((acc, ds) => {
    acc[ds.id] = {
      status: DependencyFlowStatus.Staging,
    };
    return acc;
  }, {});
};

export const getInitialManuallyTriggeredDataSources = (
  dataSources?: Record<string, DataSourceEntity | undefined>,
) => {
  const manualDS = dataSources ? DataSourceHelper.getManualPageLoadDataSources(dataSources) : [];

  return manualDS.reduce<GlobalStateStore['manuallyTriggeredDatasources']>((acc, ds) => {
    const key = getIdWithPrefix(REQUEST_ID_PREFIX);
    acc[key] = {
      callback: () => Promise.resolve(),
      dataSourceId: ds.id,
    };
    return acc;
  }, {});
};
