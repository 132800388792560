import React, { useState, useCallback } from 'react';
import useToggle from '@unifyapps/hooks/useToggle';

// Type for the remount function
type RemountFunction = () => void;

// Props for the Remount component
interface RemountProps {
  children: (remount: RemountFunction) => React.ReactNode;
}

function Remount({ children }: RemountProps) {
  const [value, { toggle }] = useToggle(true);

  const remount = useCallback(() => {
    toggle();
  }, [toggle]);

  return <React.Fragment key={value ? 0 : 1}>{children(remount)}</React.Fragment>;
}

export type { RemountFunction };
export default Remount;
