import type { BlockStateUnionType } from '@unifyapps/defs/types/block';
import _pick from 'lodash/pick';
import { EMPTY_ARRAY } from '../../../../consts/empty';
import type { InterfaceStoreStateGetterAndSetter } from '../types';
import { buildDependencyGraph } from '../../GlobalStateStore/utils/buildDependencyGraph';
import type { EntityDependency } from '../../GlobalStateStore';
import { getBlockFromInterfacePage } from './getBlockFromInterfacePage';
import _omit from 'lodash/omit';

export function updateBlockStateByActiveDevice({
  storeArgs,
  blockId,
}: {
  storeArgs: InterfaceStoreStateGetterAndSetter;
  blockId: string;
}) {
  const { get, getDeviceDetails, registry, updateBlockState, setDependencies } = storeArgs;
  const currentDevice = getDeviceDetails().device;
  const interfacePage = get().interfacePages[get().activeInterfacePageId];

  const block = getBlockFromInterfacePage(interfacePage, currentDevice, blockId);

  if (!block) {
    console.error(`updateBlockStateByActiveDevice: Block with id ${blockId} not found`);
    return;
  }

  const nextBlockState = registry.getBlockInitialState(block.component.componentType, block);

  if (nextBlockState) {
    // update only block state content appearance slots and events as
    //they can only be changed by the user rest state is persisted
    updateBlockState(blockId, (draft) => {
      Object.assign(
        draft as BlockStateUnionType,
        _pick(nextBlockState, ['appearance', 'content', 'slots', 'events']),
      );
    });

    const dependency = buildDependencyGraph({ [blockId]: _omit(block, 'events') });

    setDependencies(blockId, dependency[blockId] ?? (EMPTY_ARRAY as EntityDependency[]));
  }
}
