import _set from 'lodash/set';
import _without from 'lodash/without';
import _map from 'lodash/map';
import { createDraft, produce } from 'immer';
import { invariant } from 'ts-invariant';
import type { BlockId } from '@unifyapps/defs/types/block';
import type {
  CardComponentType,
  ContainerComponentType,
  RepeatableComponentType,
  StackComponentType,
} from '@unifyapps/defs/types/blocks';
import type { TabsComponentType } from '@unifyapps/defs/blocks/Tabs/types';
import type { SlotType } from '@unifyapps/defs/types/slot';
import { NEW_BLOCK_ID } from '../../../const';
import type { InterfaceStoreStateGetterAndSetter } from '../types';
import { updateBlockStateByActiveDevice } from '../utils/updateBlockStateByActiveDevice';

export type RemoveBlockContext = {
  parentBlockId: BlockId;
  deletePos: string | null;
};

export type RemoveBlockActionProps = {
  blockId: string;
  context: RemoveBlockContext;
};

export type RemoveBlockActionReturnType = {
  success: boolean;
  blockId: BlockId;
};

function getDraftInterfacePage(get: InterfaceStoreStateGetterAndSetter['get']) {
  const activePageId = get().activeInterfacePageId;
  const currentPage = get().interfacePages[activePageId];
  invariant(currentPage, `getRemoveBlockAction: Page with id ${activePageId} not found`);
  return createDraft(currentPage);
}

// This action is used in cut-paste like behavior, for example dragging a block from one container to another
const getRemoveBlockAction =
  (storeArgs: InterfaceStoreStateGetterAndSetter) =>
  ({ blockId, context }: RemoveBlockActionProps): RemoveBlockActionReturnType => {
    const { get, set } = storeArgs;
    const activePageId = get().activeInterfacePageId;

    // STEP 0: Remove the reference of the parent block from the block
    if (context.parentBlockId) {
      const currentPage = get().interfacePages[activePageId];
      const block = currentPage.properties.blocks?.[blockId];

      if (block) {
        get().actions.updateBlock.forAllDevices({
          blockId,
          updateBlock: (draftBlock) => {
            _set(draftBlock, 'parentId', NEW_BLOCK_ID);
          },
        });
      }
    }

    // STEP 1: delete the block reference from the layout
    if (!context.parentBlockId && context.deletePos) {
      const _draftCurrentPage = getDraftInterfacePage(get);
      const layout = _draftCurrentPage.properties.layout;
      invariant(layout, `getRemoveBlockAction: Layout not found in page with id ${activePageId}`);

      // check if this is okay
      layout[context.deletePos] = NEW_BLOCK_ID;
      const _finishedPage = createDraft(_draftCurrentPage);

      set((state) => {
        return produce(state, (draft) => {
          draft.interfacePages = {
            ...state.interfacePages,
            [activePageId]: _finishedPage,
          };
        });
      });
    }

    // STEP 2: delete the block reference from the parent block
    if (context.parentBlockId) {
      get().actions.updateBlock.forAllDevices({
        blockId: context.parentBlockId,
        updateBlock: (draftBlock) => {
          // SETP 2.1: delete block reference from parent slot
          if ('slots' in draftBlock.component && draftBlock.component.slots && context.deletePos) {
            const slot = (draftBlock.component as CardComponentType).slots?.[context.deletePos] as
              | SlotType
              | undefined;

            if (slot) {
              slot.blockId = NEW_BLOCK_ID;
            }
          }

          // SETP 2.2: delete block reference from parent items (tabs)

          if (
            'content' in draftBlock.component &&
            'items' in draftBlock.component.content &&
            draftBlock.component.content.items &&
            context.deletePos
          ) {
            (draftBlock.component as TabsComponentType).content.items = (
              draftBlock.component as TabsComponentType
            ).content.items?.map((item) => {
              if (item.value === context.deletePos) {
                return {
                  ...item,
                  blockId: NEW_BLOCK_ID,
                };
              }
              return item;
            });
          }
          // STEP 2.3: delete block reference from parent blockIds

          if ('content' in draftBlock.component && 'blockIds' in draftBlock.component.content) {
            if (draftBlock.component.componentType === 'Stack') {
              const newBlockIds = _without(draftBlock.component.content.blockIds, blockId);
              (draftBlock.component as StackComponentType).content.blockIds = newBlockIds;
            } else {
              // for all other components (like container), we need to keep the blockIds count same
              const newBlockIds = _map(draftBlock.component.content.blockIds, (id) =>
                id === blockId ? NEW_BLOCK_ID : id,
              );
              (draftBlock.component as ContainerComponentType).content.blockIds = newBlockIds;
            }
          }
          // STEP 2.4: delete block reference from parent blockId

          if ('content' in draftBlock.component && 'blockId' in draftBlock.component.content) {
            // NOTE: for Repeatable, the blockId is always placed in blockId
            if (draftBlock.component.content.blockId === blockId) {
              (draftBlock.component as RepeatableComponentType).content.blockId = NEW_BLOCK_ID;
            }
          }
        },
      });
      // STEP 3: update parent block's state
      updateBlockStateByActiveDevice({
        storeArgs,
        blockId: context.parentBlockId,
      });
    }

    return { success: true, blockId };
  };

export default getRemoveBlockAction;
