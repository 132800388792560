import type { InterfaceStoreStateGetterAndSetter } from '../types';

const getClearTouchedBlocks =
  ({ get, set }: InterfaceStoreStateGetterAndSetter) =>
  () => {
    set({
      touchedBlocks: new Set(),
    });
  };

export default getClearTouchedBlocks;
