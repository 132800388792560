export enum DeviceVariantType {
  DESKTOP = 'desktop',
  TABLET = 'tablet',
  MOBILE = 'mobile',
}

export const deviceTypes = Object.values(DeviceVariantType);

export enum MobileVariant {
  Iphone14AndIphone15ProMax = 'iphone14Pro',
  Iphone13And14 = 'iphone14',
  Iphone8 = 'iphone8',
  IphoneX = 'iphoneX',
  GooglePixel6Pro = 'googlePixel6Pro',
  GooglePixel = 'googlePixel',
  GalaxyS8 = 'galaxyS8',
}

export const DEFAULT_MOBILE_VARIANT = MobileVariant.Iphone14AndIphone15ProMax;
export const DEFAULT_DEVICE_VARIANT = DeviceVariantType.DESKTOP;

export type DeviceDetails = {
  base: DeviceVariantType;
};
