import type { BlockType } from '@unifyapps/defs/types/block';
import { createDraft, finishDraft } from 'immer';
import * as JSONDiffPatch from 'jsondiffpatch';
import type { Delta, Filter, Context } from 'jsondiffpatch';
import {
  diffFilter,
  patchFilter,
  collectChildrenPatchFilter,
  reverseFilter,
  collectChildrenReverseFilter,
} from 'jsondiffpatch-arrays-by-hash/dist/jsondiffpatch-arrays-by-hash.esm.js';
import _isObject from 'lodash/isObject';
import _toString from 'lodash/toString';

const instance = JSONDiffPatch.create({
  // Define an object hash function
  objectHash: function getObjectHash(obj, index: number) {
    if (_isObject(obj)) {
      return (obj as { id: string }).id;
    }

    return _toString(index);
  },
  // Setting matchByPosition as a fallback for when objectHash returns undefined can create smaller diffs
  matchByPosition: true,
});

// Replace the default array filter with jsondiffpatch-arrays-by-hash array filter for the pipes we need to care about
instance.processor.pipes.diff.replace('arrays', diffFilter as Filter<Context<unknown>>);
instance.processor.pipes.patch
  .replace('arrays', patchFilter as Filter<Context<unknown>>)
  .replace('arraysCollectChildren', collectChildrenPatchFilter as Filter<Context<unknown>>);
instance.processor.pipes.reverse
  .replace('arrays', reverseFilter as Filter<Context<unknown>>)
  .replace('arraysCollectChildren', collectChildrenReverseFilter as Filter<Context<unknown>>);

export const createBlockDiff = ({
  baseBlock,
  nonBaseBlock,
}: {
  baseBlock: BlockType;
  nonBaseBlock: BlockType;
}) => {
  return instance.diff(baseBlock, nonBaseBlock);
};

//instance.patch is not a pure func it mutate the block so wrapping in createDraft and finishDraft
export const applyBlockDiff = ({ block, diff }: { block: BlockType; diff: Delta }) =>
  finishDraft(instance.patch(createDraft(block), diff)) as BlockType;
