import { getIsDataSourceAnEntityType } from '../../utils/entityDataSource';
import { useDataSourceRecordStore } from './DataSourceRecordStoreProvider';

export const useDataSourceRecords = () => {
  return useDataSourceRecordStore().use.dataSources();
};

export const useUnsavedDataSourceRecords = () => {
  return useDataSourceRecordStore().use.unsavedDataSources();
};

export const useDataSourceRecordsSchemas = () => {
  return useDataSourceRecordStore().use.dataSourceSchemas();
};

export const useDataSourceExists = () => {
  const getState = useDataSourceRecordStore().getState;
  const dataSourceExists = (dataSourceId: string) => {
    const dataSources = getState().dataSources;
    return Boolean(dataSources[dataSourceId]) || getIsDataSourceAnEntityType(dataSourceId);
  };

  return { dataSourceExists };
};

export const useDataSourceRecordsCount = () => {
  const dataSources = useDataSourceRecords();
  return Object.keys(dataSources).length;
};
