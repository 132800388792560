'use client';
import React, { createContext, useContext } from 'react';

export type RouterContextType = {
  push: (url: string, useNativeRouter?: boolean) => void;
  replace: (url: string, useNativeRouter?: boolean) => void;
  back: () => void;
  pathParams?: Record<string, string | string[]>;
  searchParams: URLSearchParams;
  pathname: string;
};

const RouterStoreContext = createContext<RouterContextType | null>(null);

export const useRouter = () => {
  const store = useContext(RouterStoreContext);
  if (!store) {
    throw new Error('Missing RouterProvider');
  }
  return store;
};

export function InterfaceRouterProvider(props: {
  router: RouterContextType;
  children: React.ReactNode;
}) {
  return (
    <RouterStoreContext.Provider value={props.router}>{props.children}</RouterStoreContext.Provider>
  );
}
