import _findIndex from 'lodash/findIndex';
import type { Event } from '@unifyapps/defs/types/event';
import { createDraft, finishDraft } from 'immer';
import type { BlockType, VisibilityPayloadType } from '@unifyapps/defs/types/block';
import { invariant } from 'ts-invariant';
import type { Action } from '@unifyapps/defs/types/action';
import { CallbackActionType } from '@unifyapps/defs/types/action';
import type { InterfaceStoreStateGetterAndSetter } from '../types';
import { getBlockFromInterfacePage } from '../utils/getBlockFromInterfacePage';

export type BlockInteractionActionProps =
  | {
      operation: 'UPDATE_EVENT';
      payload: {
        blockId: string;
        eventId: string;
        event: Event;
      };
    }
  | {
      operation: 'UPDATE_ACTION';
      payload: {
        blockId: string;
        eventId: string;
        actionId?: string;
        action: Action;
        type: CallbackActionType;
      };
    }
  | {
      operation: 'SET_EVENTS';
      payload: {
        blockId: string;
        events: Event[];
      };
    }
  | {
      operation: 'UPDATE_VISIBILITY';
      payload: {
        visibility?: VisibilityPayloadType;
        blockId: string;
      };
    };

const getBlockInteractionActions =
  ({ get, getDeviceDetails }: InterfaceStoreStateGetterAndSetter) =>
  (args: BlockInteractionActionProps) => {
    const { device } = getDeviceDetails();

    const { actions } = get();
    const { updateBlock } = actions;
    const { operation } = args;

    const pageId = Object.keys(get().interfacePages)[0];

    if (!pageId) return;

    switch (operation) {
      case 'SET_EVENTS': {
        const { blockId, events } = args.payload;
        const block = getBlockFromInterfacePage(get().interfacePages[pageId], device, blockId);

        invariant(block, 'Block not found');

        updateBlock.forActiveDevice({ block: { ...block, events }, blockId });
        break;
      }
      case 'UPDATE_EVENT': {
        const { eventId, blockId } = args.payload;
        const block = getBlockFromInterfacePage(get().interfacePages[pageId], device, blockId);

        invariant(block, 'Block not found');

        const draftBlock = createDraft(block) as BlockType;
        const events = draftBlock.events;

        if (!events) return;

        const eventIndex = _findIndex(events, (event) => event.id === eventId);

        if (eventIndex === -1) return;

        draftBlock.events = [
          ...events.slice(0, eventIndex),
          args.payload.event,
          ...events.slice(eventIndex + 1),
        ];

        updateBlock.forActiveDevice({ block: finishDraft(draftBlock), blockId });
        break;
      }
      case 'UPDATE_ACTION': {
        const { blockId, eventId, actionId, action, type } = args.payload;
        const block = getBlockFromInterfacePage(get().interfacePages[pageId], device, blockId);
        invariant(block, 'Block not found');
        const draftBlock = createDraft(block) as BlockType;
        const events = draftBlock.events;

        draftBlock.events = events?.map((event) => {
          if (event.id === eventId) {
            const eventAction = event.action;
            if (type === CallbackActionType.SUCCESS) {
              eventAction.onSuccessActions = eventAction.onSuccessActions?.map((a) =>
                a.id === actionId ? action : a,
              );
              // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- TODO: look later
            } else if (type === CallbackActionType.FAILURE) {
              eventAction.onErrorActions = eventAction.onErrorActions?.map((a) =>
                a.id === actionId ? action : a,
              );
            }
            return { ...event, action: eventAction };
          }
          return event;
        });
        updateBlock.forActiveDevice({ block: finishDraft(draftBlock), blockId });
        break;
      }
      case 'UPDATE_VISIBILITY': {
        const { blockId, visibility } = args.payload;
        const block = getBlockFromInterfacePage(get().interfacePages[pageId], device, blockId);
        invariant(block, 'Block not found');
        const draftBlock = createDraft(block) as BlockType;
        draftBlock.visibility = visibility;
        updateBlock.forActiveDevice({ block: finishDraft(draftBlock), blockId });
      }
    }
  };

export default getBlockInteractionActions;
