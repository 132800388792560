import { create } from 'zustand';
import type { CurrentUserDetails } from '@unifyapps/network/generated/models/currentUserDetails';
import { createSelectors } from '../utils/state/zustand';

const useUserStoreBase = create<{
  currentUserDetails: CurrentUserDetails | undefined;
  actions: { setCurrentUserDetails: (currentUserDetails: CurrentUserDetails | undefined) => void };
}>()((set) => {
  return {
    currentUserDetails: undefined,
    actions: {
      setCurrentUserDetails: (currentUserDetails: CurrentUserDetails | undefined) => {
        set({ currentUserDetails });
      },
    },
  };
});

const userStore = createSelectors(useUserStoreBase);

export default userStore;
