import type { BlockId, BlockStateUnionType, BlockType } from '@unifyapps/defs/types/block';
import _reduce from 'lodash/reduce';
import type { Event } from '@unifyapps/defs/types/event';
import _omit from 'lodash/omit';
import { FOOTER_ID, HEADER_ID, NEW_BLOCK_ID, ROOT_ID } from '../const';
import { BlockDataAttributes } from '../hooks/useBlockDataAttributes';

/**
 * Checks if the block doesn't exist
 * @param blockId -- The block id
 *
 * stringified null is used when extracting blockId from data attributes
 *
 * `null` check is done for backward compatibility
 */
export const isEmptyBlock = (blockId: BlockId | undefined) =>
  blockId === null || blockId === NEW_BLOCK_ID || blockId === 'null';

/**
 * Returns key for react, we're using blockId and index to make it unique, since blockId can be null
 * @param blockId - block id to be rendered
 * @param index - index of the block
 */
export const getBlockKey = (blockId: BlockId, index: number) => `${blockId}_${index}`;

export function getIsRootBlock(blockId: BlockId): boolean {
  return blockId === ROOT_ID;
}

export const getIsRenameAllowed = (blockId: BlockId) =>
  blockId !== ROOT_ID && blockId !== HEADER_ID && blockId !== FOOTER_ID;

/**
 * Returns all the events from all the blocks
 * @param blocks -- The blocks
 */
export const getAllBlockEvents = (blocks: Record<string, BlockType>) =>
  _reduce(
    blocks,
    (acc, block) => {
      const { events } = block;
      if (events) {
        acc.push(...events);
      }
      return acc;
    },
    [] as Event[],
  );

export const getTargetBlockElement = ({
  isRuntimeBlock,
  targetBlockId,
  blockId,
}: {
  isRuntimeBlock: boolean | undefined;
  targetBlockId: string | undefined;
  blockId: string;
}) => {
  const block = isRuntimeBlock
    ? document.querySelector(`[${BlockDataAttributes.BlockRunTimeBlockId}="${targetBlockId}"]`)
    : document.querySelector(`[${BlockDataAttributes.BlockId}="${blockId}"]`);

  return block;
};

type OmitKeys<T, K extends keyof T> = Omit<T, K>;

export const omitKeysFromBlock = <T extends BlockStateUnionType, K extends keyof T>(
  block: BlockStateUnionType | undefined,
  keys: K[],
): OmitKeys<T, K> => {
  return _omit(block, keys) as OmitKeys<T, K>;
};

export const omitKeysFromBlocks = <T extends BlockStateUnionType, K extends keyof T>(
  blocks: Record<string, T | undefined>,
  keys: K[],
): Record<string, OmitKeys<T, K>> => {
  const result: Record<string, OmitKeys<T, K>> = {};

  for (const [key, block] of Object.entries(blocks)) {
    result[key] = omitKeysFromBlock(block, keys);
  }

  return result;
};
