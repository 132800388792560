import type { InterfaceStoreStateGetterAndSetter } from '../types';

const getSetInterfacePageElAction =
  ({ set }: InterfaceStoreStateGetterAndSetter) =>
  (interfacePageEl: HTMLDivElement | null) => {
    set({
      interfacePageEl,
    });
  };

export default getSetInterfacePageElAction;
