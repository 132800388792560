type WithFallback<T> = T extends string ? string : string | null;

export function safeJsonStringify<T extends string | undefined>(
  obj: unknown,
  fallback?: T,
  space?: number,
  replacer?: (key: string, value: unknown) => unknown,
): WithFallback<T> {
  try {
    return JSON.stringify(obj, replacer, space ?? 2);
  } catch (e) {
    // console.error('Error converting to JSON', e, obj);
    return fallback !== undefined ? fallback : (null as WithFallback<T>);
  }
}

export function safeJsonParse<T extends Record<string, unknown>>(
  str: string,
  fallback?: T,
  retriever?: (key: string, value: unknown) => unknown,
): T {
  try {
    return JSON.parse(str, retriever) as T;
  } catch (e) {
    // console.error('Error parsing JSON', e, str);
    return (fallback || {}) as T;
  }
}
