import type { BlockId } from '@unifyapps/defs/types/block';
import type { DeviceVariantType } from '@unifyapps/defs/types/deviceVariant';
import type { InterfacePageEntity } from '@unifyapps/defs/types/page';

export const getBlockFromInterfacePage = (
  interfacePage: InterfacePageEntity,
  device: DeviceVariantType,
  blockId: BlockId,
) => {
  const blocks = interfacePage.properties.devices?.[device]?.blocks;

  return blocks?.[blockId ?? ''] ?? interfacePage.properties.blocks?.[blockId ?? ''];
};
