import { produce } from 'immer';
import _set from 'lodash/set';
import type { InterfaceStoreStateGetterAndSetter } from '../types';

export type BlockPropertyCounterProps = {
  type: 'INCREMENT_COUNTER' | 'DECREMENT_COUNTER';
  payload: {
    blockId: string;
    counterKey: string;
  };
};

const getBlockPropertyCounterAction =
  ({ get, set }: InterfaceStoreStateGetterAndSetter) =>
  ({ type, payload }: BlockPropertyCounterProps) => {
    const { blockId, counterKey } = payload;

    const currentPageId = get().activeInterfacePageId;
    const count =
      get().interfacePages[currentPageId].properties.metadata?._blockPropertyCounters?.[blockId]?.[
        counterKey
      ];

    let newCount = count ?? 0;
    if (type === 'INCREMENT_COUNTER') {
      newCount = newCount + 1;
    } else {
      newCount = newCount - 1;
    }

    set({
      interfacePages: produce(get().interfacePages, (draftPages) => {
        _set(
          draftPages,
          [currentPageId, 'properties', 'metadata', '_blockPropertyCounters', blockId, counterKey],
          newCount,
        );
      }),
    });

    return newCount;
  };

export default getBlockPropertyCounterAction;
