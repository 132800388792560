import { produce } from 'immer';
import _set from 'lodash/set';
import type { InterfaceStoreStateGetterAndSetter } from '../types';

const getIncrementFunctionCounter =
  ({ get, set }: InterfaceStoreStateGetterAndSetter) =>
  () => {
    const currentPageId = get().activeInterfacePageId;
    const count = get().interfacePages[currentPageId].properties.metadata?._functionCounter;
    const newCount = (count ?? 0) + 1;

    set({
      interfacePages: produce(get().interfacePages, (draftPages) => {
        _set(draftPages, [currentPageId, 'properties', 'metadata', '_functionCounter'], newCount);
      }),
    });

    return newCount;
  };

export default getIncrementFunctionCounter;
