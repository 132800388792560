import type { BlockComponentType, BlockId, BlockComponentStateId } from '../../types/block';
import type { StyleObject } from '../../types/styleObject';
import type { ConditionalValueFilters } from '../../types/conditionalValueFilters';

export enum CarouselContentType {
  STATIC = 'STATIC',
  DYNAMIC = 'DYNAMIC',
}

type CarouselContent =
  | {
      type: CarouselContentType.DYNAMIC;
      data?: string;
      primaryKey?: string;
      blockId?: BlockId;
      defaultSlideIndex?: number | ConditionalValueFilters<number>;
    }
  | {
      type: CarouselContentType.STATIC;
      items?: {
        label: string;
        value: string;
        blockId: BlockId;
      }[];
      defaultSlideIndex?: number | ConditionalValueFilters<number>;
    };

type CarouselAppearance = {
  enableLoop?: boolean;
  enableNavigationButtons?: boolean;
  autoPlay?: {
    enabled: boolean;
    duration?: number;
  };
  viewDetails?: {
    perView: number;
    gap: number;
  };
  styles?: Pick<
    StyleObject,
    | 'padding'
    | 'margin'
    | 'height'
    | 'maxHeight'
    | 'minHeight'
    | 'width'
    | 'minWidth'
    | 'maxWidth'
    | 'layout'
    | 'gap'
  >;
};

export type CarouselComponentType = BlockComponentType<
  'Carousel',
  CarouselContent,
  CarouselAppearance
>;

export type CarouselComponentStateType = Pick<
  CarouselComponentType,
  'appearance' | 'componentType'
> & {
  content: CarouselContent & {
    currentSlideIndex: number;
    totalSlidesCount: number;
  };
} & BlockComponentStateId;
