'use client';

import { createContext, useContext, useMemo } from 'react';
import _isEmpty from 'lodash/isEmpty';

export const BlockRuntimeStateContext = createContext<
  | {
      data?: Record<string, unknown>;
      runTimeBlockKey?: string;
    }
  | undefined
>(undefined);

export const useBlockRuntimeData = () => {
  const context = useContext(BlockRuntimeStateContext);
  return context?.data;
};

export const useRuntimeBlockKey = () => {
  const context = useContext(BlockRuntimeStateContext);
  return context?.runTimeBlockKey;
};

export function BlockRuntimeStateContextProvider({
  data,
  runTimeBlockKey,
  children,
}: {
  data?: Record<string, unknown>;
  runTimeBlockKey?: string;
  children: React.ReactNode;
}) {
  const value = useMemo(
    () => ({
      data,
      runTimeBlockKey,
    }),
    [data, runTimeBlockKey],
  );

  return (
    <BlockRuntimeStateContext.Provider value={value}>{children}</BlockRuntimeStateContext.Provider>
  );
}

// this ResetBlockRuntimeStateProvider is used to reset the access of parent block runtime in child block
// the usecase of this is that when we render a Block that renders InterfaceResourceProvider(or renderes a page in simple terms) nested inside Repeatable, we don't want the child blocks of the new page to consider themselves as repeatable runtime block
export function ResetBlockRuntimeStateProvider({ children }: { children: React.ReactNode }) {
  return (
    <BlockRuntimeStateContext.Provider value={undefined}>
      {children}
    </BlockRuntimeStateContext.Provider>
  );
}
