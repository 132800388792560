import { produce } from 'immer';
import _set from 'lodash/set';
import type { InterfaceStoreStateGetterAndSetter } from '../types';

export type SetPageHasUnsavedChangesProps = { pageId: string; pageHasUnsavedChanges: boolean };

const getSetPageHasUnsavedChanges =
  ({ get, set }: InterfaceStoreStateGetterAndSetter) =>
  ({ pageId, pageHasUnsavedChanges }: SetPageHasUnsavedChangesProps) => {
    set({
      dirtyPages: produce(get().dirtyPages, (draft) => {
        if (pageHasUnsavedChanges) {
          draft.add(pageId);
        } else {
          draft.delete(pageId);
        }
      }),
    });
  };

export default getSetPageHasUnsavedChanges;
