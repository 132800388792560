import type { BlockId, BlockType, ComponentsUnionType } from '@unifyapps/defs/types/block';
import type { WritableDraft } from 'immer';
import { finishDraft, produce } from 'immer';
import _set from 'lodash/set';
import _update from 'lodash/update';
import type { PageBody } from '@unifyapps/defs/types/page';
import { CarouselContentType } from '@unifyapps/defs/blocks/Carousel/types';
import { getIsTableBlock } from '@unifyapps/defs/blocks/Table/common';
import type { InterfaceStoreStateGetterAndSetter } from '../types';
import { updateBlockStateByActiveDevice } from '../utils/updateBlockStateByActiveDevice';
import { getDraftInterfacePage } from '../utils/getDraftInterfacePage';

export type InsertBlockContext = {
  parentBlockId: BlockId;
  insertPos: string | null;
  initialBlockData?: Partial<ComponentsUnionType>;
  blockDisplayName?: string;
  enableActiveOnCreate?: boolean;
};

export type InsertBlockActionProps = {
  blockId: BlockId;
  context?: InsertBlockContext;
};

export type InsertBlockActionReturnType = {
  success: boolean;
  blockId: BlockId;
};

const isContentBlockIdNeedsToBeUpdated = (block: BlockType, context: InsertBlockContext) =>
  block.component.componentType === 'Repeatable' ||
  (block.component.componentType === 'NavigationContainer' && context.insertPos === '0') ||
  (block.component.componentType === 'Carousel' &&
    block.component.content.type === CarouselContentType.DYNAMIC);

/**
 * Given a parent block and context of insertion, returns the updated parent block with the new block inserted
 * @param parentBlock -- The parent block where the new block will be inserted
 * @param context -- The context of insertion
 * @param id -- The id of the new block
 */
function getUpdatedParentBlock(
  parentBlock: WritableDraft<BlockType>,
  context: InsertBlockContext,
  id: string,
) {
  const parentBlockDraft = parentBlock;
  // Step 1: If content.blockId need to updated then do that
  if (isContentBlockIdNeedsToBeUpdated(parentBlockDraft, context)) {
    _update(parentBlockDraft, ['component', 'content', 'blockId'], () => id);
  }

  // Step 2: If it's a Stack
  else if (parentBlockDraft.component.componentType === 'Stack') {
    _update(
      parentBlockDraft,
      ['component', 'content', 'blockIds'],
      (ids: string[] | undefined = []) => {
        // either insert at the specified index or at the second last position
        const insertIndex = context.insertPos ? parseInt(context.insertPos) : ids.length - 1;
        if (insertIndex === ids.length) {
          // add to second last
          ids.splice(insertIndex - 1, 0, id);
        } else {
          ids.splice(insertIndex, 0, id);
        }
        return ids;
      },
    );
  }

  // Step 2: If slotId is provided, update the slot with the new block id
  else if (
    'slots' in parentBlockDraft.component &&
    parentBlockDraft.component.slots &&
    context.insertPos !== null
  ) {
    parentBlockDraft.component.slots[context.insertPos] = { blockId: id, wrappedInLayout: true };
  }

  // Step 2.1: If it's a Table and slots was not present, create slots and update the slot with the new block id
  else if (getIsTableBlock(parentBlockDraft) && context.insertPos !== null) {
    if (!parentBlockDraft.component.slots) {
      parentBlockDraft.component.slots = {};
    }
    parentBlockDraft.component.slots[context.insertPos] = { blockId: id, wrappedInLayout: true };
  }

  // Step 3: If itemId is provided, update the item with the new block id
  else if (
    'content' in parentBlockDraft.component &&
    'items' in parentBlockDraft.component.content &&
    parentBlockDraft.component.content.items &&
    context.insertPos !== null
  ) {
    parentBlockDraft.component.content.items = parentBlockDraft.component.content.items.map(
      (item: { value: string; label: string; blockId: BlockId }) => {
        if (item.value === context.insertPos) {
          item.blockId = id;
        }
        return item;
      },
    );
  }

  // Step 4: If insertIndex is provided, update the blockIds with the new block id
  else if (
    'content' in parentBlockDraft.component &&
    'blockIds' in parentBlockDraft.component.content &&
    parentBlockDraft.component.content.blockIds &&
    context.insertPos !== null
  ) {
    if (context.insertPos) {
      parentBlockDraft.component.content.blockIds = parentBlockDraft.component.content.blockIds.map(
        (blockId, index) => {
          if (index.toString() === context.insertPos) {
            return id;
          }
          return blockId;
        },
      );
    } else {
      parentBlockDraft.component.content.blockIds.push(id);
    }
  }
}

const getInsertBlockAction =
  (storeArgs: InterfaceStoreStateGetterAndSetter) =>
  ({ blockId, context }: InsertBlockActionProps): InsertBlockActionReturnType => {
    //* added to handle potential null values of blockId
    if (!blockId) {
      return { success: false, blockId };
    }

    const { get, set } = storeArgs;
    // Step 1: Get the active page id, where the block will be inserted
    const activePageId = get().activeInterfacePageId;
    const activePage = get().interfacePages[activePageId];
    if (context?.parentBlockId) {
      get().actions.updateBlock.forAllDevices({
        blockId,
        updateBlock: (draftBlock) => {
          if (context.parentBlockId) {
            _set(draftBlock, 'parentId', context.parentBlockId);
          }
        },
      });
    }

    // Step 2: If no context is provided, only add the block in the map
    if (!context) {
      console.debug('getInsertBlockAction: No context provided, inserting block directly');
      return { success: true, blockId };
    }

    // Step 3: Get the parent block where the new block will be inserted
    if (context.parentBlockId) {
      const parentBlock = activePage.properties.blocks?.[context.parentBlockId];

      if (!parentBlock) {
        // This is error since action caller is responsible for providing the parent block
        throw new Error(
          `getInsertBlockAction: Parent block not found for blockId ${context.parentBlockId} while inserting blockId ${blockId}`,
        );
      }

      get().actions.updateBlock.forAllDevices({
        blockId: context.parentBlockId,
        updateBlock: (draftBlock) => {
          // Step 3.1: update the parent block with the new block id
          getUpdatedParentBlock(draftBlock, context, blockId);
        },
      });

      // Step 3.2: update the parent block state
      updateBlockStateByActiveDevice({
        blockId: context.parentBlockId,
        storeArgs,
      });

      return { success: true, blockId };
    }

    // Step 4: insert the block in the layout
    if (!context.parentBlockId && context.insertPos) {
      const draftActivePage = getDraftInterfacePage(get);

      const layout = draftActivePage.properties.layout;
      if (!layout) {
        throw new Error(
          `getInsertBlockAction: Layout not found for ${context.insertPos} while inserting blockId ${blockId}}`,
        );
      }
      // Step 5.1 update the layout with the new block id
      layout[context.insertPos as keyof PageBody] = blockId;

      const finishedPage = finishDraft(draftActivePage);

      set((state) => {
        return produce(state, (draft) => {
          draft.interfacePages = {
            ...state.interfacePages,
            [activePageId]: finishedPage,
          };
        });
      });

      return { success: true, blockId };
    }

    throw new Error(
      `getInsertBlockAction: unreachable code reached while inserting blockId ${blockId} with context ${JSON.stringify(context)}`,
    );
  };

export default getInsertBlockAction;
