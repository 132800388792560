import _reduce from 'lodash/reduce';
import _forEach from 'lodash/forEach';
import { EXTRACT_DATA_BIND_REGEX } from '../no-code/utils/dynamicBindings';

const OBJECT_PATH_SEPARATOR_REGEX = /\.|\['|'\]/;

export const getSelectedDataSourcePath = ({
  data,
}: {
  data?: string;
}): { pathArray: string[]; fullPath: string[]; dataSourceId?: string } => {
  if (typeof data !== 'string') {
    return {
      pathArray: [],
      fullPath: [],
      dataSourceId: '',
    };
  }

  /* Example:-
   * data: {{ <dataSourceId>.data.objects }}
   * result: "<dataSourceId>.data.objects"
   */
  const result: string[] | null | undefined = data.match(EXTRACT_DATA_BIND_REGEX);

  /* Example:-
   * result: "<dataSourceId>.data.objects"
   * pathArrayWithDataSourceId: ["<dataSourceId>", "data", "objects"]
   */
  const pathArrayWithDataSourceId = result?.[1]
    ? result[1].split(OBJECT_PATH_SEPARATOR_REGEX).filter(Boolean)
    : [];

  // Remove the first element which is the dataSourceId
  /* Example:-
   * pathArrayWithDataSourceId: ["<dataSourceId>", "data", "objects"]
   * pathArray: ["data", "objects"]
   */
  const pathArray = pathArrayWithDataSourceId.length
    ? pathArrayWithDataSourceId.slice(1, pathArrayWithDataSourceId.length).filter(Boolean)
    : [];

  return {
    pathArray,
    fullPath: pathArrayWithDataSourceId,
    dataSourceId: pathArrayWithDataSourceId[0],
  };
};
