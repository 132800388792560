import { useMemo } from 'react';
import { useGlobalStateStore } from './GlobalStateStoreProvider';
import type { QueryRequestResult } from './types';

export const useGetGlobalStateStoreState = () => {
  return {
    getGlobalStateStoreState: useGlobalStateStore().getState,
  };
};

export const useGetDataSourcesAggregationMetadata = (dataSourceIds: string[]) => {
  const state = useGlobalStateStore().use.dataSourcesMeta();
  return useMemo(() => {
    return dataSourceIds.reduce<Record<string, QueryRequestResult | undefined>>((acc, id) => {
      acc[id] = state[id]?.aggregationMetadata;
      return acc;
    }, {});
  }, [dataSourceIds, state]);
};

export const useGetDeviceDetailsInStateStore = () => {
  return useGlobalStateStore().use.deviceDetails();
};

export const useDeviceDetailsGetter = () => {
  const { getGlobalStateStoreState } = useGetGlobalStateStoreState();
  const getDeviceDetails = () => getGlobalStateStoreState().deviceDetails;
  return { getDeviceDetails };
};
