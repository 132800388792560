import type { StoreApi, UseBoundStore } from 'zustand';

export type { UseBoundStore } from 'zustand';

/**
 * Refer: https://docs.pmnd.rs/zustand/guides/auto-generating-selectors
 */

/**
 * NOTE: this is used when store is created using zustand/create which return a store hook
 * Refer: https://docs.pmnd.rs/zustand/guides/auto-generating-selectors#create-the-following-function:-createselectors
 */
export type WithSelectors<S> = S extends {
  getState: () => infer T;
}
  ? S & { use: { [K in keyof T]: () => T[K] } }
  : never;

export type StoreApiWithSelectors<S> = WithSelectors<UseBoundStore<StoreApi<S>>>;

export const createSelectors = <S extends UseBoundStore<StoreApi<object>>>(
  _store: S,
): WithSelectors<S> => {
  const store = _store as WithSelectors<typeof _store>;
  store.use = {};
  for (const k of Object.keys(store.getState())) {
    store.use[k] = () => store((s) => s[k as keyof typeof s]);
  }

  return store;
};
