import type { CircularProgressProps as BaseCircularProgressProps } from '@mui/joy/CircularProgress';
import { default as BaseCircularProgress } from '@mui/joy/CircularProgress';
import { forwardRef, useMemo } from 'react';
import type { LoaderProps, LoaderSizes, LoaderVariants } from './types';
import { loaderRootVariants, loaderTrackVariants, loaderProgressVariants } from './styles';

const variantValueMap: Record<LoaderVariants, number> = {
  arc: 30,
  circle: 25,
};

// (outer width - stroke thickness) will be actual diameter
const sizeRadiusMap: Record<LoaderSizes, number> = {
  xxs: (16 - 2) / 2,
  xs: (20 - 2) / 2,
  sm: (32 - 4) / 2,
  md: (48 - 6) / 2,
  lg: (56 - 6) / 2,
  xl: (64 - 8) / 2,
};

const Loader = forwardRef<HTMLSpanElement, LoaderProps>((props, ref): JSX.Element => {
  const { className, strokeColor, size = 'sm', variant = 'circle', slotProps, ...rest } = props;

  const circularProgressSlotProps = useMemo<BaseCircularProgressProps['slotProps']>(
    () => ({
      root: {
        className: loaderRootVariants({
          variant,
          size,
        }),
        ...slotProps?.root,
      },
      track: {
        className: loaderTrackVariants({
          variant,
          size,
        }),
        style: {
          // @ts-expect-error -- r is valid prop for <cricle>
          r: sizeRadiusMap[size],
        },
      },
      progress: {
        className: loaderProgressVariants({
          variant,
          size,
          className: strokeColor,
        }),
        style: {
          // @ts-expect-error -- r is valid prop for <cricle>
          r: sizeRadiusMap[size],
        },
      },
    }),
    [strokeColor, size, variant, slotProps?.root],
  );

  return (
    <BaseCircularProgress
      className={className}
      determinate={false}
      ref={ref}
      value={variantValueMap[variant]}
      {...rest}
      slotProps={circularProgressSlotProps}
    />
  );
});

Loader.displayName = 'Loader';

export { BaseCircularProgress as CircularProgress };
export default Loader;
