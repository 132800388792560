import type { BlockCompositionData, BlockId, BlockType } from '@unifyapps/defs/types/block';
import _concat from 'lodash/concat';
import _reduce from 'lodash/reduce';
import _values from 'lodash/values';
import _byKeys from 'lodash/keyBy';
import _castArray from 'lodash/castArray';
import _merge from 'lodash/merge';
import { BLOCK_PREFIX } from '../../../const';
import { getIdWithPrefix } from '../../../../utils/id';
import { getBulkCreateBlocks } from '../utils/block';
import type { InterfaceStoreStateGetterAndSetter } from '../types';
import { CompositeBlockHelper } from '../helper/CompositeBlockHelper';

export type CreateCompositeBlockActionProps = {
  block: Omit<BlockType, 'id' | 'displayName'>;
  blockCompositionData: BlockCompositionData;
};

export type CreateCompositeBlockActionReturnType =
  | {
      success: false;
      blockId: null;
    }
  | {
      success: true;
      blockId: BlockId;
    };

const getBlockWithId = (
  block: Omit<BlockType, 'id'> & {
    displayName?: string;
  },
  get: InterfaceStoreStateGetterAndSetter['get'],
  persistDisplayName?: boolean,
): BlockType => {
  const componentType = block.component.componentType;
  const newCount = get().actions.incrementBlockCounter(componentType);
  const id = getIdWithPrefix(BLOCK_PREFIX);
  const displayName = CompositeBlockHelper.generateDisplayName({
    componentType,
    newCount,
    persistDisplayName: Boolean(persistDisplayName),
    block,
  });
  const blockWithId: BlockType = { ...block, displayName, id };

  return blockWithId;
};

const getCreateCompositeBlockAction =
  (storeArgs: InterfaceStoreStateGetterAndSetter) =>
  ({
    block,
    blockCompositionData,
  }: CreateCompositeBlockActionProps): CreateCompositeBlockActionReturnType => {
    const { get, registry, setBlocksState } = storeArgs;
    // Step 1: Get the active page id, where the block will be inserted
    const activePageId = get().activeInterfacePageId;
    if (!activePageId) {
      console.debug('getCreateBlockAction: No active page found');
      return { success: false, blockId: null };
    }

    const bulkCreateBlocks = getBulkCreateBlocks(storeArgs);

    // Step 2: Create a root block with a unique id
    const rootBlock = blockCompositionData.mergeWithBlockInitialFormData
      ? _merge(
          {
            component: registry.getBlockInitialFormData(block.component.componentType),
          },
          { ...block },
        )
      : block;

    const rootBlockWithId = getBlockWithId(rootBlock, get);

    // Step 3: Create child blocks with unique ids
    const childBlocks = _reduce(
      blockCompositionData.childBlocks,
      (acc, childBlock, key) => {
        const _childBlock: BlockType = blockCompositionData.mergeWithBlockInitialFormData
          ? _merge(
              {
                component: registry.getBlockInitialFormData(childBlock.component.componentType),
              },
              { ...childBlock },
            )
          : childBlock;

        const childBlockWithId = getBlockWithId(
          _childBlock,
          get,
          blockCompositionData.persistDisplayName,
        );
        acc[key] = childBlockWithId;
        return acc;
      },
      {},
    );

    // Step 4: Stitch the blocks together

    const { rootBlock: composedRootBlock, childBlocks: composedChildBlock } =
      blockCompositionData.compose
        ? blockCompositionData.compose({
            rootBlock: rootBlockWithId,
            childBlocks,
          })
        : { rootBlock: rootBlockWithId, childBlocks };

    const blocksArray = _concat(_castArray(composedRootBlock), _values(composedChildBlock));

    const blocks = _byKeys(blocksArray, 'id');

    bulkCreateBlocks({
      blocks,
      pageId: activePageId,
    });

    const initialBlocksState = _reduce(
      blocksArray,
      (acc, _block: BlockType) => {
        const initialBlockState = registry.getBlockInitialState(
          _block.component.componentType,
          _block,
        );
        if (initialBlockState) {
          acc[_block.id] = initialBlockState;
        }
        return acc;
      },
      {},
    );

    // Step 3.1: optional for blocks not having definition
    setBlocksState(initialBlocksState);

    return { success: true, blockId: rootBlockWithId.id };
  };

export default getCreateCompositeBlockAction;
