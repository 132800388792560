import type { BlockId } from '@unifyapps/defs/types/block';
import type { InterfaceStoreStateGetterAndSetter } from '../types';

const getSetHoverBlockIdAction =
  ({ set, get }: InterfaceStoreStateGetterAndSetter) =>
  (hoverBlockId: BlockId) => {
    if (get().hoverBlockId === hoverBlockId) {
      return;
    }
    set({ hoverBlockId });
  };

export default getSetHoverBlockIdAction;
