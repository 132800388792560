import _omit from 'lodash/omit';
import { invariant } from 'ts-invariant';
import type { InterfaceStoreStateGetterAndSetter } from '../types';
import { updateBlockStateByActiveDevice } from '../utils/updateBlockStateByActiveDevice';

export type RemoveSlotActionProps = {
  blockId: string;
  slotId: string;
};

const getRemoveSlotInBlock =
  (storeArgs: InterfaceStoreStateGetterAndSetter) =>
  ({ blockId, slotId }: RemoveSlotActionProps) => {
    const { get, setBlockState } = storeArgs;
    // TODO: We don't support active page id yet, REPLACE with active page id
    const activePageId = get().activeInterfacePageId;
    const currentPage = get().interfacePages[activePageId];
    invariant(currentPage, `Page with id ${activePageId} not found`);

    get().actions.updateBlock.forAllDevices({
      blockId,
      updateBlock: (draftBlock) => {
        if ('slots' in draftBlock.component) {
          const newSlots = _omit(draftBlock.component.slots, slotId);
          draftBlock.component.slots = newSlots;
        }
      },
    });

    updateBlockStateByActiveDevice({
      blockId,
      storeArgs,
    });

    setBlockState(slotId, undefined);
  };

export default getRemoveSlotInBlock;
