import { getDynamicBindings } from './dynamicBindings';
import { recursiveUpdate } from './recursiveUpdate';

// * The pattern matches:
// * - \s* (zero or more whitespace characters)
// * - (?<entityId>[\w$]+) (named capturing group `entityId` that captures one or more word characters or dollar signs)
// * - \. (literal period character ".")
// The named capturing group `entityId` will contain the desired identifier value.
export const ID_FROM_STRING = /\s*(?<entityId>[\w$]+)\./;
// * The pattern matches:
// * - \s* (zero or more whitespace characters)
// * - (?<entityId>[\w$]+) (named capturing group `entityId` that captures one or more word characters or dollar signs)
// * - \[ (literal opening square bracket character "[")
const ID_FROM_STRING_NEW = /\s*(?<entityId>[\w$]+)\[/;

export function getEntityIdsReferencedInBlock(obj: object) {
  const entityIds = new Set<string>();
  recursiveUpdate(obj, (value: string) => {
    const dynamicBinding = getDynamicBindings(value);
    if (dynamicBinding.jsSnippets.length) {
      dynamicBinding.jsSnippets.forEach((jsSnippet) => {
        const matches = ID_FROM_STRING.exec(jsSnippet);
        if (matches?.groups?.entityId) {
          entityIds.add(matches.groups.entityId);
        }
        const matchesNew = ID_FROM_STRING_NEW.exec(jsSnippet);
        if (matchesNew?.groups?.entityId) {
          entityIds.add(matchesNew.groups.entityId);
        }
      });
    }
    return {};
  });

  return Array.from(entityIds);
}
