import type { BlockId } from '@unifyapps/defs/types/block';
import type { InterfaceStoreStateGetterAndSetter } from '../types';

const getSetActiveBlockIdAction =
  ({ set, onChangeActiveBlockId }: InterfaceStoreStateGetterAndSetter) =>
  (activeBlockId: BlockId) => {
    onChangeActiveBlockId?.(activeBlockId);
    set({
      activeBlockId,
      pageSettingDetails: null,
    });
  };

export default getSetActiveBlockIdAction;
