import { invariant } from 'ts-invariant';
import type { BlockId } from '@unifyapps/defs/types/block';
import type { InterfaceStoreStateGetterAndSetter } from '../types';
import { updateBlockStateByActiveDevice } from '../utils/updateBlockStateByActiveDevice';

export type ReorderBlockContext = {
  parentBlockId: string;
  newIndex: number;
};

export type ReorderBlockActionProps = {
  blockId: string;
  reorderContext: ReorderBlockContext;
};

export type ReorderBlockActionReturnType = {
  success: boolean;
  blockId: string;
};

function moveBlock(
  source: BlockId[],
  params: {
    blockId: string;
    newIndex: number;
  },
) {
  const copySource = [...source];
  const { blockId, newIndex } = params;
  const currentIndex = copySource.indexOf(blockId);

  // Remove the element from its current position
  copySource.splice(currentIndex, 1);

  // Insert the element before the target index
  copySource.splice(currentIndex < newIndex ? newIndex - 1 : newIndex, 0, blockId);

  return copySource;
}

const getReorderBlockAction =
  (storeArgs: InterfaceStoreStateGetterAndSetter) =>
  ({ blockId, reorderContext }: ReorderBlockActionProps): ReorderBlockActionReturnType => {
    const { get } = storeArgs;
    const activePageId = get().activeInterfacePageId;
    const reordered = false;

    try {
      const currentPage = get().interfacePages[activePageId];
      invariant(currentPage, `getReorderBlockAction: Page with id ${activePageId} not found`);

      const parentBlock = currentPage.properties.blocks?.[reorderContext.parentBlockId];
      invariant(
        parentBlock,
        `getReorderBlockAction: Parent block with id ${reorderContext.parentBlockId} not found`,
      );

      // STEP 1: reorder the blockIds array in the parent block
      // we are only supporting reordering blockIds field
      // so the caller should ensure that the parent block has blockIds field, otherwise it should behave as a noop
      get().actions.updateBlock.forAllDevices({
        blockId: reorderContext.parentBlockId,
        updateBlock: (draftBlock) => {
          if ('content' in draftBlock.component && 'blockIds' in draftBlock.component.content) {
            const oldBlockIds = draftBlock.component.content.blockIds;
            if (!oldBlockIds) return draftBlock;
            const newBlockIds = moveBlock(oldBlockIds, {
              blockId,
              newIndex: reorderContext.newIndex,
            });
            draftBlock.component.content.blockIds = newBlockIds;
          }
        },
      });

      // STEP 2: update the parent block state according to the current device view
      updateBlockStateByActiveDevice({
        storeArgs,
        blockId: reorderContext.parentBlockId,
      });
    } catch (e) {
      return { success: false, blockId };
    }

    return { success: reordered, blockId };
  };

export default getReorderBlockAction;
